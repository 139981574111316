@import "../tools/mixins";
@import "../tools/breakpoints";

.about-menu{
  padding: 20px 0;
  border-bottom: 1px solid #d8d8d8;
  &__row{
    @media (max-width: $breakpoint-lg){
      .column{
        @include grid-size(100%);
        margin-bottom: 15px;
      }
    }
  }
  &__list{
    @media (max-width: $breakpoint-lg){
     text-align: center;
    }
  }
  &__item{
    display: inline-block;
    @media (max-width: $breakpoint-md){
      display: block;
      text-align: center;
      margin-bottom: 10px;
    }
    & + & {
      margin-left: 70px;
      @media (max-width: $breakpoint-xl){
        margin-left: 45px;
      }
      @media (max-width: $breakpoint-lg){
        margin-left: 30px;
      }
      @media (max-width: $breakpoint-md){
        margin-left: 0;
      }
    }
  }
  &__link{
    display: block;
    font-size: 20px;
    font-weight: normal;
    line-height: normal;
    color: var(--col-black);
    @include transition;
    &:hover{
      color: var(--col-primary);
    }
  }
  &__{}
}

.about-belt{
  &__row{
    @media (max-width: $breakpoint-xl){
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
  }
  &__img{
    position: absolute;
    right: 15px;
    @media (max-width: $breakpoint-xl){
      position: initial;
      right: initial;
      max-width: 100%;
    }
    @media (max-width: $breakpoint-lg){
      margin-bottom: 30px;
    }

    @media (max-width: 1450px) and (min-width: 1198px) {
    width: 95%;
    height: auto !important;
    }
  }
}

.about-who{
  background: url(../img/background/about-who-bg.png) center no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  &__carousel{
    margin: auto;
    width: 470px;
    @media (max-width: $breakpoint-xl){
      padding: 0 30px;
      width: 500px;
    }
    @media (max-width: $breakpoint-md){
      width: auto;
      max-width: 500px;
    }
    .flickity-prev-next-button{
      color: #fff;
    }
    .flickity-prev-next-button.previous{
      left: -30px;
      @media (max-width: $breakpoint-xl){
        left: -10px;
      }
    }
    .flickity-prev-next-button.next{
      right: -20px;
      @media (max-width: $breakpoint-xl){
        right: 0;
      }
    }
  }
  &__cell{}
}

.about-bronko{
  &__row{
    @media (max-width: $breakpoint-xl){
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    @media (max-width: $breakpoint-lg){
      margin-bottom: 30px;
    }
    &.--row-reverse{
      -webkit-flex-flow: row-reverse  wrap;
      flex-flow: row-reverse wrap;
      .about-bronko__content{
        padding-left: 15px;
      }
      .about-bronko__image{
        left: initial;
        right: 0;
        @media (max-width: $breakpoint-xl){
          right: initial;
        }
      }
    }
  }
  &__image{
    position: absolute;
    left: 0;
    @media (max-width: $breakpoint-xl){
      position: initial;
      left: initial;
      max-width: 100%;
    }
  }
  &__content{
    height: 390px;
    padding-right: 15px;
    @media (max-width: $breakpoint-xl){
      height: initial;
      padding: 30px 0;
    }
  }
  &__title-row{
    display: flex;
    margin-bottom: 25px;
  }
  &__title{
    display: block;
    font-size: 36px;
    font-weight: bold;
    line-height: 1.11;
    color: var(--col-black);
    @media (max-width: $breakpoint-md){
      font-size: 28px;
    }
    @media (max-width: $breakpoint-sm){
      font-size: 22px;
    }
  }
  &__icon{
    @media (max-width: $breakpoint-sm){
      max-width: 120px;
    }
  }
  &__{}
}

.contacts{
  &__block{
    padding-left: 35px;
    margin-bottom: 30px;
  }
  &__title{
    display: block;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.31;
    color: var(--col-grey);
    margin-bottom: 20px;
  }
  &__item{
    position: relative;
    margin-bottom: 15px;
    list-style: none;
    i{
      position: absolute;
      left: -30px;
      font-size: 18px;
      color: var(--col-black);
    }
  }
  &__{}
}

.contacts-social{
  &__item{
    display: inline-block;
    & + &{
      margin-left: 30px;
    }
  }
  &__link{
    display: block;
    font-size: 40px;
    color: var(--col-primary);
    @include transition;
    &:hover{
      color: var(--col-black);
    }
  }
}

.article{
  &__top{
    margin-bottom: 25px;
  }
  &__content{
    padding-bottom: 40px;
    margin-bottom: 50px;
    border-bottom: 1px solid #eef0f2;
    img{
      margin-bottom: 30px;
    }
  }

  &__link{
    span{
      margin-bottom: 10px;
    }
    a{
      font-weight: bold;
    }
  }
  &__author{
    span:nth-child(1){
      font-style: italic;
    }
  }

  &__bottom{
    .column{
      @media (max-width: $breakpoint-md){
        margin-bottom: 15px;
        text-align: center;
      }
    }
  }
  &__category{
    text-transform: uppercase;
  }
}

.news{
  &__sidebar{}
}

.news-title{
  &__date{
    display: block;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.31;
    color: #a0a0a0;
  }
  &__title{
    display: block;
    font-size: 22px;
    font-weight: 600;
    line-height: normal;
    color: var(--col-black);
  }
}

.news-post{
  background-color: #fff;
  border: solid 1px #d8d8d8;
  padding-top:15px;
  margin-bottom: 30px;
  & > a > img {
    padding-left:15px;
  }
  &__content{
    padding: 15px;
  }
  &__head{
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #eef0f2;
  }
  &__bottom{}
  &__desc{
    color: #a0a0a0;
    @include text-ellipsis(3);
    margin-bottom: 25px;
  }
  &__category{
    text-transform: uppercase;
  }
}

.news-search{
  margin-bottom: 30px;
  &__icon{
    position: absolute;
    left: 8px;
    top: 12px;
    color: var(--col-grey);
  }
  &__input{
    padding: 0 105px 0 30px;
  }
  &__btn{
    position: absolute;
    right: 0;
    top: 0;
    width: 100px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}

.news-sign{
  padding: 15px;
  border: solid 1px #d8d8d8;
  margin-bottom: 30px;
  &__input{
    padding-right: 75px;
  }
  &__btn{
    position: absolute;
    right: 0;
    top: 0;
    width: 70px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
  &__soc{
    .icon-instagram{
      color: #864c2d;
    }
    .icon-facebook{
      color: #6081c4;
    }
    .icon-youtube{
      color: #f15a4b;
    }
  }
  &__soc-item{
    display: inline-block;
    & + &{
      margin-left: 30px;
    }
  }
  &__soc-link{
    display: block;
    font-size: 40px;
  }
}

.news-categories{
  padding: 15px;
  border: solid 1px #d8d8d8;
  &__list{}
  &__list-item{
    display: block;
    margin-bottom: 10px;
  }
  &__list-link{
    display: block;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    color: var(--col-grey);
    @include transition;
    &:hover{
      color: var(--col-primary);
    }
  }
}

.pagination{
  text-align: center;
  margin-bottom: 30px;
  @media (max-width: $breakpoint-md){
    margin-bottom: 45px;
  }
  &__list{
    text-align: center;
  }
  &__first-item{
    display: inline-block;
    margin-right: 15px;
  }
  &__last-item{
    display: inline-block;
    margin-left: 15px;
  }
  &__item{
    display: inline-block;
    & + & {
      margin-left: 10px;
    }
  }
  &__link{
    display: block;
    text-align: center;
    font-weight: 600;
    padding: 3px 6px;
    color: var(--col-primary);
    @include transition;
    &:hover{
      color: #fff;
      background-color: var(--col-primary);
    }
    &.active{
      color: #fff;
      background-color: var(--col-primary);
    }
    &.disabled{
      color: #a0a0a0;
    }
    &.icon-pagination-left, &.icon-pagination-right{
      font-size: 14px;
      &:hover{
        color: initial;
        background-color: transparent;
      }
    }
  }
}

.news-column{
  &__column{
    max-width: 400px;
    margin-bottom: 30px;
    @media (max-width: $breakpoint-sm){
      margin: auto auto 30px;
    }
    &:hover{
      .news-column__content{
        background-color: var(--col-primary);
        span{
          color: #fff;
        }
      }
    }
  }
  &__content{
    position: relative;
    padding: 10px 15px 20px 15px;
    background-color: #fff;
    @include transition;

  }
  &__date{
    display: block;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.31;
    color: #a0a0a0;
    margin-bottom: 5px;
  }
  &__title{
    display: block;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.23;
    color: var(--col-black);
    @media (max-width: $breakpoint-lg){
      font-size: 16px;
    }
  }
}
.news-section, .news-article {
  padding-top:0;
}
#videoHolder {
  position: relative;
  #nextVideo {
    position: absolute;
    z-index:3;
    bottom:10px;
    right:-200px;
    transition: all 1s ease-in-out;
    &.show {
      right:10px;
    }
    .block_title {
      font-weight:bold;
      color:#fff;
      margin-bottom:5px;
    }
    .video_thumb {
      width:184px;
      height:105px;
      border:#fff 1px solid;
      overflow: hidden;
      a {
        display:block;
        position: relative;
      }
      img {
        width:100%;
        height:auto;
      }
      .video_title {
        position: absolute;
        bottom:0;
        left:0;
        z-index:2;
        width:100%;
        padding:10px;
        color:#fff;
        font-weight:bold;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .overlay {
        position: absolute;
        z-index:1;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 23%, rgba(0, 0, 0, 0.55));
      }
      &:hover {
        .overlay {
          display:none;
        }
      }
    }
  }
}