@import "../tools/mixins";
@import "../tools/breakpoints";

.top-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background-color: #414141;
  transition: top 0.2s ease-in-out;
  &.hideit {
    top:-25px;
  }
  &.nav-up {
    // height: 0;
    overflow: hidden;
  }

  &.nav-down {
    overflow: hidden;
    height: 65px;
  }
  &__container {
    margin: auto;
    max-width: 1310px;
    padding: 0 15px;
  }
  &__row {
    height: 35px;
    @media (max-width: 655px) {
      height: 25px;
      padding: 5px 0;
      .column {
        @include grid-size(100%);
        text-align: center;
      }
    }
    @media (max-width: $breakpoint-sm) {
      height: 25px;
    }
  }

  &__right {
    @include grid-size(290px);
    @media (max-width: $breakpoint-xl) {
      display: none !important;
    }
  }

  &__info {
    display: block;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: normal;
    color: #a0a0a0;
  }
  &__list {
    text-align: right;
    @media (max-width: 655px) {
      text-align: center;
    }
  }
  &__list-item {
    display: inline-block;
    & + & {
      margin-left: 40px;
    }
  }
  &__list-link {
    display: block;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    color: #a0a0a0;
    @include transition;
    &:hover {
      color: #fff;
    }
  }
}

.header {
  position: fixed;
  top: 35px;
  left: 0;
  right: 0;
  z-index: 99;
  background-color: var(--col-black);
  transition: height 0.2s ease-in-out;

  @media (max-width: 655px) {
    top: 25px;
  }
  &.js-fixed {
    position: fixed;
    top: 0;

    // @media (max-width: $breakpoint-lg) {
    //   overflow: hidden;
    // }
  }
  @media (max-width: $breakpoint-lg) {
    &.nav-up {
      height: 0;
      overflow: hidden;
    }
    &.nav-down {
      // height:65px;
    }
  }
  @media (max-width: $breakpoint-lg) {
    .logo-holder {
      display: inline-block;
      width: 43px;
      height: 62px;
      background: url(../img/logos/bronko.png) no-repeat 50%;
      background-size: cover;
      overflow: hidden;
      img {
        display: none;
      }
    }
    .mobile-holder {
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      display: flex;

      &.nav-up {
        // height: 0;
        overflow: hidden;
      }

      &.nav-down {
        overflow: hidden;
        height: 65px;
      }

      & > li {
        width: auto;
      }
    }
  }

  &.js-open {
    @media (max-width: $breakpoint-lg) {
      .header__row {
        height: 65px;
      }
      .header__logo {
        // max-width: 100%;
      }
      .hamburger {
        top: 0;
        padding: 15px;
      }
      .header-cart {
        top: 35px;
      }
      .header-search {
        top: 35px;
      }
    }
    @media (max-width: $breakpoint-sm) {
      .header__row {
        height: 65px;
      }
      .header__logo {
        max-width: 155px;
      }
      .hamburger {
        top: 0;
        padding: 15px;
      }
      .header-cart {
        top: 20px;
      }
      .header-search {
        top: 20px;
      }
    }
  }
  &__container {
    margin: auto;
    max-width: 1310px;
    padding: 0 15px;
  }
  &__row {
    height: 100px;
    position: relative;
    @include transition;
    @media (max-width: $breakpoint-lg) {
      height: 65px;
    }
  }

  &__logo {
    @include transition;
    @media (max-width: $breakpoint-lg) {
      max-width: 155px;
    }
    @media (max-width: $breakpoint-sm) {
      // display: none;
    }
  }
}

.header-nav {
  font-size: 0;
  text-align: center;

  &__item {
    display: inline-block;
    position: relative;

    @media (max-width: $breakpoint-lg) {
      display: block;
      margin-bottom: 10px;
    }
    & + & {
      //margin-left: 35px;
      //@media (max-width: $breakpoint-lg) {
      //  margin-left: 0;
      //}
    }
    &:hover {
      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: 25px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: var(--col-primary);

        @media (max-width: $breakpoint-lg) {
          // bottom: -10px;
          display: none;
        }
      }

      > .header-megamenu {
        display: block;

        @media (max-width: $breakpoint-lg) {
          display: none;
        }
      }
    }
  }
  &__link {
    position: relative;
    padding: 40px 17px;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    color: #fff;
    @include transition;
    @media (max-width: $breakpoint-lg) {
      padding: 5px;
    }

    &:hover {
      // &:before {
      //   content: '';
      //   position: absolute;
      //   left: 50%;
      //   margin-left: -5px;
      //   bottom: 25px;
      //   width: 10px;
      //   height: 10px;
      //   border-radius: 50%;
      //   background-color: var(--col-primary);
      //   @media (max-width: $breakpoint-lg) {
      //     // bottom: -10px;
      //     display: none;
      //   }
      // }
    }

    &.active {
      color: var(--col-primary);

      @media (max-width: $breakpoint-sm) {
        color: var(--col-primary);
      }
    }
    // active mob class
    &.active-mob {
      color: var(--col-primary);

      @media (min-width: $breakpoint-lg) {
        // bottom: -10px;
        // display: none;

        // &:after{
        //   content: '';
        //   position: absolute;
        //   left: 50%;
        //   margin-left: -5px;
        //   bottom: 25px;
        //   width: 10px;
        //   height: 10px;
        //   border-radius: 50%;
        //   background-color: var(--col-primary);
        // }
      }

      @media (min-width: $breakpoint-lg) {
        color: #fff;
      }

      &.js-link-active {
        &:before {
          transform: scale(-1);
        }
        &:after {
          transform: scale(-1);
          content: "";
          right: -10px;
          top: 11px;
          position: absolute;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 6px 4px 0 4px;
          border-color: #ffffff transparent transparent transparent;
          // @include transition;
        }
      }
    }
    // end of active mob class

    &:hover {
      color: #fff;

      @media (max-width: $breakpoint-lg) {
        color: var(--col-white);
      }
    }

    @media (max-width: $breakpoint-lg) {
      &:before,
      &:after {
        content: "";
        right: -10px;
        top: 11px;
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 4px 0 4px;
        border-color: #ffffff transparent transparent transparent;
        @include transition;
      }

      &.js-link-active + .header-megamenu {
        display: block;
      }
      &.js-link-active {
        &:before {
          transform: scale(-1);
        }
        &:after {
          transform: scale(-1);
          content: "";
          right: -10px;
          top: 11px;
          position: absolute;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 6px 4px 0 4px;
          border-color: #ffffff transparent transparent transparent;
          // @include transition;
        }
      }
    }
  }
}

.header-megamenu {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 135px;
  height: 345px;
  background-color: #fff;

  @media (max-width: $breakpoint-lg) {
    position: relative;
    left: 0;
    right: 0;
    top: 0 !important;
    height: initial;
    background-color: transparent;
  }
  &__container {
    margin: auto;
    padding: 0 15px;
    max-width: 1310px;
  }
  &__wrap {
    padding: 35px 0;
    @media (max-width: $breakpoint-lg) {
      padding: 0;
    }
  }

  &__bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    background-color: var(--col-primary);
    @media (max-width: $breakpoint-lg) {
      display: none;
    }
  }
  &__title {
    display: block;
    font-size: 40px;
    font-weight: bold;
    line-height: normal;
    text-align: left;
    color: var(--col-black);
    @media (max-width: $breakpoint-lg) {
      display: none;
    }
  }
  &__column {
    @media (max-width: $breakpoint-lg) {
      display: none;
    }
  }
  &__list {
    text-align: left;
    @media (max-width: $breakpoint-lg) {
      text-align: center;
    }
  }
  &__list-item {
    display: block;
  }
  &__list-link {
    display: block;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.88;
    color: var(--col-grey);
    @include transition;
    &:hover {
      color: var(--col-primary);
    }
  }
  &__block {
    max-width: 270px;
    margin: auto;
    background-color: #fff;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    &:hover {
      .header-megamenu__caption {
        color: #fff;
        background-color: var(--col-primary);
      }
    }
  }
  &__caption {
    position: relative;
    top: -1px;
    display: block;
    padding: 10px;
    font-size: 20px;
    font-weight: bold;
    line-height: normal;
    text-align: left;
    color: var(--col-black);
    @include transition;
  }
}

.header-mobile {
  @media (max-width: $breakpoint-lg) {
    position: absolute;
    top: 32px;
    right: -15px;
    min-height: 100vh;
    bottom: 0;
    width: 300px;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: var(--col-black);
    padding-bottom: 45px;
    transform: translateX(300px);
    @include transition;
    @media (max-width: $breakpoint-lg) {
      top: 0;
    }
    &.js-show {
      transform: translateX(0);
    }
    .column {
      @include grid-size(100%);
      padding: 0;
      margin: 0;
    }
    .header-megamenu__container {
      padding: 0;
    }
    .m-row {
      margin: 0;
      padding: 7px 0;
    }
  }
}

.header-menu {
  text-align: right;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  @media (max-width: $breakpoint-lg) {
    text-align: center;
    justify-content: center;
  }

  &__item {
    display: inline-block;
    &:nth-child(2) {
      display: none;
    }
    @media (max-width: $breakpoint-lg) {
      margin-left: 0 !important;
      width: 100%;
    }
    & + & {
      margin-left: 40px;
    }
    @media (max-width: $breakpoint-lg) {
      &:nth-child(1) {
        display: none;
      }
      &:nth-child(2) {
        display: none;
      }
      &:nth-child(3) {
        display: none;
      }
    }

    &:nth-child(3) {
      margin-left: 15px !important;
    }
  }
  &__link  {
    &.icon-search {
      display: inline-block;
    }
  &:hover {
    color: #FFFFFF;
  }

    & svg {
      vertical-align: inherit !important;
    }

    display: block;
    position: relative;
    font-size: 26px;
    color: #fff;
    @include transition;
    &.--text {
      text-transform: uppercase;
      font-size: 16px;
      font-weight: bold;
      line-height: normal;
      div {
        vertical-align: middle;
        width: 35px;
        display: inline-block;
      }
    }
    &.--cart {
      i {
        position: absolute;
        top: -2px;
        left: 21px;
        padding: 0 4px;
        vertical-align: middle;
        height: 15px;
        text-align: center;
        font-size: 13px;
        border-radius: 10px;
        line-height: 15px;
        font-family: "Myriad Pro", sans-serif;
        background-color: var(--col-primary);
      }
    }
  }

  & .search_website[type="text"] {
    //  width: 200px!important;
    //transition: width .4s cubic-bezier(0.000, 0.795, 0.000, 1.000);
    -webkit-transition: all 0.9s ease-in-out;
    transition: all 0.9s ease-in-out;
  }

  & .search_website:focus {
    border-color: var(--col-primary) !important;
    box-shadow: none !important;
    // width: 200px!important;
  }
}

.header-drop {
  position: relative;
  right: 20px;
  @media (max-width: $breakpoint-lg) {
    position: inherit;
    right: 0;
    width: 100%;
  }
  &.is-active {
    .header-drop__icon {
      transform: scale(-1);
    }
    .header-drop__block {
      display: block;
    }
  }
  &__btn {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 28px;
    color: #afafaf;
    padding-right: 25px;
    @include transition;
    @media (max-width: $breakpoint-lg) {
      display: none;
    }
    &:hover {
      color: #fff;
    }
  }
  &__icon {
    position: absolute;
    right: 0;
    top: 11px;
    z-index: -1;
    font-size: 8px;
    color: #fff;
    @include transition;
  }
  &__block {
    display: none;
    position: absolute;
    right: 0;
    top: 65px;
    width: 225px;
    padding: 12px;
    // padding: 15px 15px 0px 15px;
    background-color: var(--col-black);
    text-align: left;
    @media (max-width: $breakpoint-lg) {
      display: block;
      margin-top: 30px;
      text-align: center;
      padding: 0;
      right: initial;
      top: initial;
      position: relative;
      width: auto;
    }
    @media (max-width: $breakpoint-sm) {
      position: inherit;
      right: 0;
      left: 0;
      margin: 0;
      width: 100%;
    }
  }

  &__list {
  }
  &__item {
    display: block;
    margin-bottom: 12px;
    &:nth-last-child(1) {
      margin-bottom: 0;
      a {
        // color: var(--col-grey);
        &:hover {
          color: var(--col-primary);
        }
      }
    }
    &.--drop-item {
      display: none;
      @media (max-width: $breakpoint-lg) {
        display: block;
      }
    }

    &#cart-welcome {
      font-size: 16px;
      color: var(--col-primary);
      font-weight: 600;
      text-transform: uppercase;
    }
  }
  &__link {
    position: relative;
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    color: #fff;
    @include transition;
    &.--drop {
      display: none;
      @media (max-width: $breakpoint-lg) {
        display: inline-block;
        &:before {
          content: "";
          right: -13px;
          top: 7px;
          position: absolute;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 6px 4px 0 4px;
          border-color: #ffffff transparent transparent transparent;
          -webkit-transition: all 0.3s ease-out;
          -moz-transition: all 0.3s ease-out;
          -o-transition: all 0.3s ease-out;
          transition: all 0.3s ease-out;
        }
      }

      &.js-link-active:before {
        transform: scale(-1);
      }
      &.js-link-active + .header-dashboard__content {
        display: block;
      }
    }
    &:hover {
      color: var(--col-primary);
    }
    &.active {
      // padding-left: 20px;
      color: var(--col-primary);
      &:before {
        content: "";
        // position: absolute;
        // left: 0;
        // top: 4px;
        // width: 10px;
        // height: 10px;
        // border-radius: 50%;
        background-color: var(--col-primary);
      }
    }
  }
}

.header-dashboard {
  &__name {
    display: none;
    @media (max-width: $breakpoint-lg) {
      display: block;
      font-size: 16px;
      font-weight: bold;
      line-height: normal;
      color: var(--col-primary);
      text-align: center;
    }
  }
  &__content {
    margin-top: 15px;
    @media (max-width: $breakpoint-lg) {
      display: none;
    }
  }
  &__link {
    display: block;
    color: #fff;
    margin-bottom: 10px;
  }
}

.header__logo-mobile {
  display: none;
  @media (max-width: $breakpoint-sm) {
    display: block;
    max-width: 45px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.header-phone {
  display: none;
  position: absolute;
  left: 75px;
  top: 20px;
  @media (max-width: $breakpoint-sm) {
    display: block;
  }
}

.header-search {
  display: none;
  position: absolute;
  right: 75px;
  top: 20px;
  @media (max-width: $breakpoint-lg) {
    display: block;
  }
  @media (max-width: $breakpoint-sm) {
    right: initial;
    left: 15px;
  }
}

.header-cart {
  display: none;
  position: absolute;
  right: 120px;
  top: 20px;
  @media (max-width: $breakpoint-lg) {
    display: block;
  }
  @media (max-width: $breakpoint-sm) {
    right: 75px;
  }
}

.hamburger {
  display: none;
}

@media (max-width: $breakpoint-lg) {
  .hamburger {
    display: block;
    position: absolute;
    top: 8px;
    right: 0;
    overflow: visible;
    margin: 0;
    padding: 23px 15px;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transition-duration: 0.15s;
    transition-duration: 0.15s;
    -webkit-transition-property: opacity, -webkit-filter;
    transition-property: opacity, -webkit-filter;
    transition-property: opacity, filter;
    transition-property: opacity, filter, -webkit-filter;
    text-transform: none;
    color: inherit;
    border: 0;
    background-color: transparent;
    @media (max-width: $breakpoint-lg) {
      top: 0px;
      padding: 15px;
    }
    &,
    .hamburger__box {
      cursor: pointer;
      display: inline-block;
    }
    &__box {
      position: relative;
      width: 32px;
      height: 25px;
      top: 1px;
    }
    &__inner {
      display: block;
      margin-top: -2px;
      &,
      &:before,
      &:after {
        position: absolute;
        width: 32px;
        height: 3px;
        border-radius: 10px;
        top: auto;
        bottom: 0;
        -webkit-transition-delay: 0.13s;
        transition-delay: 0.13s;
        -webkit-transition-timing-function: cubic-bezier(
          0.55,
          0.055,
          0.675,
          0.19
        );
        transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        -webkit-transition-duration: 0.13s;
        transition-duration: 0.13s;
        -webkit-transition-property: -webkit-transform;
        transition-property: -webkit-transform;
        transition-property: transform;
        transition-property: transform, -webkit-transform;
        background-color: #fff;
      }
      &:before,
      &:after {
        content: "";
        display: block;
      }
      &:before {
        top: -10px;
        -webkit-transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1)
            0.2s,
          -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s,
          -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s,
          transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s,
          transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19),
          -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
      &:after {
        bottom: -10px;
        top: -20px;
        -webkit-transition: top 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1)
            0.2s,
          opacity 0.1s linear;
        transition: top 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s,
          opacity 0.1s linear;
      }
    }
  }
  .is-active {
    .hamburger__inner {
      -webkit-transition-delay: 0.22s;
      transition-delay: 0.22s;
      -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
      transform: translate3d(0, -10px, 0) rotate(-45deg);
    }
    .hamburger__inner:before {
      top: 0;
      -webkit-transition: top 0.1s cubic-bezier(0.33333, 0, 0.66667, 0.33333)
          0.16s,
        -webkit-transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.25s;
      transition: top 0.1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.16s,
        -webkit-transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.25s;
      transition: top 0.1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.16s,
        transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.25s;
      transition: top 0.1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.16s,
        transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.25s,
        -webkit-transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.25s;
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
    }
    .hamburger__inner:after {
      top: 0;
      -webkit-transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
        opacity 0.1s linear 0.22s;
      transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
        opacity 0.1s linear 0.22s;
      opacity: 0;
    }
  }
  .header-drop__item {
    padding: 10px 0;
    margin-bottom: 0;
    a {
      text-transform: uppercase;
      color: #000;
    }
  }
  .header-drop__block {
    .header-drop__item:nth-child(1) {
      background: #efefef;
    }
    .header-drop__item:nth-child(2) {
      background: #d2d2d4;
    }
    &.loggedin {
      .header-drop__item {
        background: #efefef;
      }
      .header-drop__item:last-child {
        background: #d2d2d4;
      }
    }
  }
}

// search form website styles

input#search-keyword {
  // width: 200px!important;
  height: 26px;
  padding: 0px 25px 0px 0px;
  background: rgb(0, 0, 0);
  vertical-align: top;
  border-bottom: 1px solid rgb(255, 255, 255);
  border-radius: 0px;
  margin-left: 5px;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  color: #fff;
  width: 0;
  transition: width 0.5s ease-in-out;
}

// input#search-keyword:-webkit-autofill() {
//   background-color: #000important;
// }

// input:-webkit-autofill {
//   -webkit-box-shadow: 0 0 0 30px white inset;
// }

.search_website {
  display: inline-block !important;
}

#search-section {
  position: relative;
}

.search-div {
  background: rgb(0, 0, 0);
  position: absolute;
  right: -3px;
  top: 0;
  z-index: 5;
  transition: width 0.5s ease-in-out;
}

button.search-close {
  position: absolute;
  top: -2px;
  right: 0px;
  color: #fff;
  text-shadow: 0 !important;
}

button.search-close:hover {
  color: #fff;
}

.search-close:not(:disabled):not(.disabled):focus,
.search-close:not(:disabled):not(.disabled):hover,
.search-close:not(:disabled):not(.disabled):hover {
  color: #fff;
  text-decoration: none;
  opacity: 0.75;
  outline: none !important;
}
.mobile-search {
  position: absolute;
  left: 0;
  top: 65px;
  z-index: 98;
  width: 100%;
  height: 0;
  overflow: hidden;
  transition: all 0.25s ease-in-out;
  // position: relative;
  &.open {
    top: 65px;
    height: 4rem;
  }
  .search-div {
    position: inherit;
    width: 100%;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    background: #414141;
    padding: 0 15px;
    & > input {
      display: block;
      color: #fff;
      padding: 0.45rem 0;
      border: 0;
      width: 100%;
      display: block;
      background: 0;
      border-bottom: #fff 1px solid;
      &::placeholder {
        color: #a0a0a0;
        opacity: 1;
      }
      &:-ms-input-placeholder {
        color: #a0a0a0;
      }
      &::-ms-input-placeholder {
        color: #a0a0a0;
      }
      &:focus {
        box-shadow: 0 !important;
        outline: none !important;
      }
    }
  }
  .icon-search {
    display: block;
    width: 35px;
    height: 35px;
    position: absolute;
    right: 15px;
    top: 2px;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    &::before {
      color: #fff;
      font-size: 1.3em;
    }
  }
}
#search-website.close-search::before {
  content: "\e90c";
}
