:root {

  --col-black: #000;
  --col-primary: #d0021b;
  --col-grey: #717171;
  --col-light-gray: #efefef;
  --col-d4: #d4d4d4;
  --col-white: #ffffff;

  --font-regular: 400;
  --font-medium: 500;
  --font-bold: 700;

}
