
[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-letter:before {
  content: "\e901";
  font-size: 15px;
}


/*******/

.icon-arrows-cw:before { 
  content: "\e800"; 
}

.icon-location2:before {
  content: "\e90b";
}
.icon-bronko-location:before {
  content: "\e91f";
  font-size: 24px;
}
.icon-play-circle:before {
  content: "\e91d";
}
.icon-circle-plus:before {
  content: "\e91e";
}
.icon-lock:before {
  content: "\e91c";
}
.icon-pagination-left:before {
  content: "\e91a";
}
.icon-pagination-right:before {
  content: "\e91b";
}
.icon-phone-letter:before {
  content: "\e919";
}
.icon-private:before {
  content: "\e916";
}
.icon-camps:before {
  content: "\e917";
}
.icon-team-training:before {
  content: "\e918";
}
.icon-remove:before {
  content: "\e914";
}
.icon-share:before {
  content: "\e913";
}
.icon-spin:before {
  content: "\e910";
}
.icon-slider-prev:before {
  content: "\e911";
}
.icon-slider-next:before {
  content: "\e912";
}
.icon-performance:before {
  content: "\e90d";
}
.icon-safety:before {
  content: "\e90e";
}
.icon-comfort:before {
  content: "\e90f";
}
.icon-close:before {
  content: "\e90c";
}
.icon-calendar:before {
  content: "\e909";
}
.icon-people:before {
  content: "\e90a";
}
.icon-chevron-down:before {
  content: "\e903";
}
.icon-chevron-right:before {
  content: "\e915";
}
.icon-phone:before {
  content: "\e821";
}
.icon-facebook:before {
  content: "\e900";
}
.icon-instagram:before {
  content: "\e902";
}
.icon-search:before {
  content: "\e904";
}
.icon-shop_cart {
  width: 32px;
  height: 25px;
  background: url(../img/svg/shop_cart.svg) center no-repeat;
  background-size: contain;
}
.icon-user {
  width: 55px;
  height: 30px;
  background: url(../img/svg/user.svg) left no-repeat;
  background-size: contain;
}
.icon-youtube:before {
  content: "\e907";
}
.icon-location:before {
  content: "\e947";
}
.icon-location2:before {
  content: "\e90b";
}
.icon-lock:before {
  content: "\e91c";
}