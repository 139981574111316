
.fade-in {
  animation: fade-in .35s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.animate-in {
  animation: modalIn .7s cubic-bezier(0.165, 0.84, 0.44, 1);
  animation-iteration-count: 1;
}

.animate-away {
  animation: modalAway 1.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  animation-iteration-count: 1;
}

@-webkit-keyframes modalIn {
  0% {
    transform: translateY(200%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes modalIn {
  0% {
    transform: translateY(200%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@-webkit-keyframes modalAway {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(200%);
  }
}

@keyframes modalAway {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(200%);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeEffect{from{opacity:0}to{opacity:1}};
