@import "../tools/mixins";
@import "../tools/breakpoints";

.orderview-table {
  padding: 15px 30px;
  border: solid 1px #d4d4d4;
  margin-bottom: 60px;

  &.orderhistory-desktop {
    @media (max-width: $breakpoint-lg) {
      display: none;
    }
  }

  .column {
    &:nth-child(1) {
      @include grid-size(16.66667%);
    }
    &:nth-child(2) {
      @include grid-size(24.99999%);
    }
    &:nth-child(3) {
      @include grid-size(16.66667%);
    }
    &:nth-child(4) {
      @include grid-size(8.33333%);
    }
    &:nth-child(5) {
      @media (max-width: $breakpoint-lg) {
        @include grid-size(100%!important);
        padding-left: 130px;
        position: relative;
        margin-bottom: 5px;
        &:before {
          // content: attr(data-head) ": ";
          position: absolute;
          left: 15px;
          font-size: 16px;
          font-weight: 600;
          color: var(--col-black);
        }
      }
    }
  }

  &__row {
    &.--head {
      margin-bottom: 20px;
      @media (max-width: $breakpoint-lg) {
        // display: none;
      }
      span {
        font-weight: 600;
        color: var(--col-black);
      }
      &.ordertwo-col {
        .column {
          &:nth-child(1) {
            @include grid-size(50%);
          }
          &:nth-child(2) {
            @include grid-size(50%);
          }
        }
      }
    }

    &.--body {
      position: relative;
      border-bottom: solid 1px #d4d4d4;
      padding-bottom: 15px;
      &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
      }
      &.ordertwo-col {
        .column {
          &:nth-child(1) {
            @include grid-size(50%);
          }
          &:nth-child(2) {
            @include grid-size(50%);
          }
        }
      }
    }
    &.--body + &.--body {
      margin-top: 10px;
    }
    span {
      display: block;
      font-size: 16px;
      font-weight: normal;
      line-height: 1.25;
      text-align: left;
      color: var(--col-grey);
    }

    .column {
      &:nth-child(1) {
        @include grid-size(16.66667%);
      }
      &:nth-child(2) {
        @include grid-size(24.99999%);
      }
      &:nth-child(3) {
        @include grid-size(50%);
      }
      &:nth-child(4) {
        @include grid-size(8.33333%);
      }
      &:nth-child(5) {
        @include grid-size(16.66667%);
      }
      &:nth-child(6) {
        @include grid-size(16.66667%);
      }
      @media (max-width: $breakpoint-lg) {
        @include grid-size(100%!important);
        // padding-left: 130px;
        position: relative;
        margin-bottom: 5px;
        &:before {
          // content: attr(data-head) ": ";
          position: absolute;
          left: 15px;
          font-size: 16px;
          font-weight: 600;
          color: var(--col-black);
        }
      }
    }
  }

  &.desktop {
    @media (max-width: $breakpoint-md) {
      display: none;
    }
  }

  &.mob {
    .row {
      &.--head {
        span {
          font-weight: 600;
          color: var(--col-black);
        }
      }
    }
    @media (min-width: $breakpoint-md) {
      display: none;
    }
  }
}

.orderview-bootstrap {
  padding: 15px 30px;
  border: solid 1px #d4d4d4;
  margin-bottom: 60px;
  margin-left: 5px;
  margin-right: 5px;

  .order-heading {
    font-weight: 600;
    color: var(--col-black);
  }

  .orderview-mob {
    padding-right: 3px !important;
    padding-left: 10px;
  }
}

.orderhistory-mob {
  padding: 15px 30px;
  border: solid 1px #d4d4d4;
  margin-bottom: 60px;

  .orderhistory-container {
    position: relative;
    border-bottom: solid 1px #d4d4d4;
    padding-top: 10px;
    padding-bottom: 15px;
  }

  @media (min-width: $breakpoint-lg) {
    display: none;
  }
}


.table-mob {
  display: none;
  @media (max-width: $breakpoint-sm) {
    display: block;
  }
}

.table-desktop {
  display: block;
  @media (max-width: $breakpoint-sm) {
    display: none;
  }
}