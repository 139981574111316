@import "../tools/mixins";
@import "../tools/breakpoints";

.belt-head{
  padding-bottom: 50px;
  border-bottom: 1px solid #d8d8d8;
  &__carousel{
    position: absolute;
    width: 805px;
    right: 15px;
    @media(max-width: 1460px){
      position: relative;
      right: initial;
      width: initial;
    }
    @media(max-width: $breakpoint-md) {
      display: none;
    }

    &-cell{
      height: 565px;
      width: 100%;
      background: center no-repeat;
      -webkit-background-size: contain;
      background-size: contain;
      @media(max-width: 1460px){
        height: 470px;
      }
      @media(max-width: $breakpoint-md){
        height: 400px;
      }
      @media(max-width: $breakpoint-sm){
        height: 300px;
      }
    }


  }
  &__navcarousel{
    // max-width: 570px;
    max-width: 480px;
    margin-top: 595px;
    @media(max-width: 1460px){
      margin: 30px auto auto;
      padding: 0 30px;
    }
    @media(max-width: $breakpoint-lg){
      margin: 30px auto auto;
    }
    @media(max-width: $breakpoint-md){
      display: none;
    }

    &-cell{
      position: relative;
      height: 120px;
      max-width: 170px;
      width: 33.3333%;
      margin-left: 30px;
      @media(max-width: $breakpoint-md){
        width: 50%;
        max-width: 100%;
      }
      @media(max-width: $breakpoint-sm){
        width: 100%;
      }
      @media(min-width: $breakpoint-md){
        max-width: 100px;
      }
      img{
        position: relative;
      }
      &.is-selected{

      }
    }
    .flickity-prev-next-button {
      font-size: 20px;
    }
    .flickity-prev-next-button.previous {
      left: -30px;
      @media(max-width: 1460px){
        left: 0;
      }
    }
    .flickity-prev-next-button.next {
      right: -30px;
      @media(max-width: 1460px){
        right: 0;
      }
    }
  }
  &__{}


  &__content{
    padding-top: 60px;
    .title-block{
      margin-bottom: 10px;
    }
  }
  &__subtitle{
    display: block;
    font-size: 20px;
    font-weight: normal;
    line-height: normal;
    color: var(--col-black);
    margin-bottom: 40px;
  }
  &__text-content{
    margin-bottom: 40px;
    .app-txt + .app-txt {
      margin-top: 10px;
    }
  }
  &__row{
    &.--type-1{
      margin-bottom: 20px;
    }
    &.--type-2{
      margin-bottom: 50px;
    }
    .column:nth-child(1){
      @include grid-size(100px);
    }
    .column:nth-child(2){
      @include grid-size(calc(100% - 100px));
    }
  }
  &__sizes{
    height: 40px;
    padding: 0 15px;
    line-height: 40px;
    border-radius: 3px;
    border: solid 1px #d4d4d4;
  }
}

.camp-head{
  // padding-bottom: 50px;
  border-bottom: 1px solid #d8d8d8;


  &__row{
    @media (max-width: $breakpoint-xl) {
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }

  }
  &__img{
    position: absolute;
    right: 15px;

    @media (max-width: 1400px) and (min-width: 1198px) {
      width: 95%;
      height: auto!important;
    }

    &.--type-1{
        height: 430px;
    }

    &.--type-2{
      height: 430px;
    }


    @media (max-width: $breakpoint-xl) {
      position: inherit;
      height: inherit!important;
      right: inherit;
      max-width: 100%;
    }
    @media (max-width: $breakpoint-lg) {
      margin-top: 45px;
    }
  }
  &__content{
    padding: 20px 0;
    &.--type-1{
      height: 405px;

    }
    &.--type-2{
      height: 470px;
    }
    @media (max-width: $breakpoint-xl) {
      height: inherit!important;
    }
  }
  &__price{
    margin-bottom: 25px;
    span{
      display: inline-block;
    }
    span:nth-child(1){
      font-size: 30px;
      font-weight: bold;
      line-height: normal;
      color: var(--col-primary);
    }
    span:nth-child(2){
      font-size: 30px;
      font-weight: normal;
      line-height: normal;
      color: var(--col-black);
    }
  }
  &__bottom{
    @media (max-width: $breakpoint-sm) {
      .column {
        @include grid-size(50%);
      }
      .column:nth-child(1){
        @include grid-size(100%);
      }
      .column{
        margin-bottom: 15px;
      }
    }
  }
  &__bottom-title{
    display: block;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
    line-height: normal;
    color: var(--col-black);
    margin-bottom: 10px;
    
  }
}


.camp-steps{
  text-align: center;
  margin-bottom: 30px;
  &__head{
    height: 105px;
    margin-bottom: 30px;
    @media (max-width: $breakpoint-md) {
      margin-bottom: 10px;
    }
  }
  &__title{
    display: block;
    max-width: 255px;
    margin: auto;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    color: var(--col-primary);
    margin-bottom: 15px;
  }
  &__txt{
    max-width: 300px;
    margin: auto;
  }
}


.camp-data{
  padding: 60px 0 30px 0;
  background-color: var(--col-light-gray);
  &__column{
    margin-bottom: 30px;
  }
  &__txt{
    position: relative;
    display: block;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    color: var(--col-grey);
    @media (max-width: $breakpoint-xl) {
      text-align: center;
    }
  }
  &__icon{
    position: relative;
    top: 3px;
    display: inline-block;
    font-size: 24px;
    color: var(--col-black);
    margin-right: 5px;
  }
}


.camp-info{
  padding-top: 40px;
  &__column{
    margin-bottom: 60px;
  }
  &__title{
    display: block;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
    line-height: normal;
    color: var(--col-black);
    margin-bottom: 15px;
  }
  &__list{
    &-title{
      display: block;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.25;
      color: var(--col-black);
      margin-bottom: 20px;
    }
    li{
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid #d8d8d8;
      width: 80%;
    }
  }
}


.camp-map{
  height: 400px;
}


.camp-sign{
  position: relative;
  background: center no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  &__overlay{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
  &__content{
    position: relative;
    z-index: 3;
  }
  @media (max-width: $breakpoint-sm){
    .btn:nth-of-type(1){
      margin-bottom: 15px;
    }
  }
}




.shop-subscript{

  &__column{
    height: 380px;
    @media (max-width: $breakpoint-lg) {
      height: initial;
    }
  }
  &__wrap{
    padding: 0 40px;
    @media (max-width: $breakpoint-xl) {
      padding: 0 15px;
    }
    @media (max-width: $breakpoint-lg) {
      padding: 15px;
      .shop-subscript__title{
        @include text-ellipsis(initial);
      }
    }
  }
  &__subtitle{
    display: block;
    text-align: center;
    font-weight: normal;
    font-size: 20px;
    line-height: normal;
    color: var(--col-grey);
    margin-bottom: 15px;
  }
  &__title{
    display: block;
    text-transform: uppercase;
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    line-height: normal;
    color: var(--col-primary);
    @include text-ellipsis(2);
    @media (max-width: $breakpoint-xl) {
      font-size: 22px;
    }
    &.--modifier{
      margin-bottom: 30px;
    }
  }

  &__content{
    position: relative;
    height: 380px;
    overflow: hidden;
    background: center no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    &:hover{
      .shop-subscript__caption{
        transform: translateY(0);
      }
    }
  }

  &__caption{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    transform: translateY(240px);
    padding: 18px 0;
    background-color: rgba(255,255,255,0.9);
    transition: all 0.3s ease-out;

    @media (max-width: $breakpoint-xl) {
      top: 20px;
    }
  }
}





.shop-benefits{
  background-color: #e6e6e6;
  &__column{
    margin-bottom: 50px;
    img{
      margin-bottom: 20px;
    }
  }
  &__txt{
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.44;
    color: var(--col-black);
  }
}


.shop-table{
  &__head{
    position: relative;
    padding: 12px 29px;
    bottom: -1px;
    display: inline-block;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
    line-height: normal;
    color: var(--col-black);
    border: 1px solid #d8d8d8;
    border-bottom: 0;
    background-color: #fff;
  }
  &__content{
    padding: 20px 25px;
    border: 1px solid #d8d8d8;
  }
  &__head-row{
    margin: 0 -5px 20px -5px;
  }
  &__row{
    margin: 0 -5px 10px -5px;
    padding-bottom: 10px;
    border-bottom: 1px solid #d8d8d8;
    &:last-of-type{
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
  &__column{
    padding: 0 5px;
    @include grid-size(33.3333%);
  }
  &__title{
    display: block;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.56;
    color: var(--col-black);
  }
  &__txt{
    display: block;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.25;
    color: var(--col-grey);
  }
  &__notify{
    margin-top: 30px;
  }
}


.shop-retail{
  &__row{
    @media (max-width: $breakpoint-lg) {
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;

      &:nth-child(odd){
        .column:nth-of-type(1){
          @include order(2);
        }
        .column:nth-of-type(2){
          @include order(1);
        }
      }
    }
  }
  &__img{
    position: absolute;
    @media (max-width: $breakpoint-lg) {
      max-width: 100%;
      position: inherit;
      right: inherit;
      left: inherit;
    }
    @media (max-width: $breakpoint-md) {
      margin: 30px 0;
    }
    &.--right{
      right: 0;
    }
    &.--left{
      left: 0;
    }
  }
  &__contacts{
    height: 345px;
    @media (max-width: $breakpoint-lg) {
      height: 300px;
    }
    @media (max-width: $breakpoint-md) {
      height: initial;
      margin: 30px 0 0;
    }
  }
  &__title{
    display: block;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
    line-height: normal;
    color: var(--col-black);
    margin-bottom: 25px;
  }
  &__item{
    position: relative;
    padding-left: 30px;
    margin-bottom: 15px;
    i{
      position: absolute;
      left: 0;
      font-size: 18px;
      color: var(--col-black);
    }
  }
  &__{}
}


.teams-shop{
  &__row{
    @media (max-width: $breakpoint-xl){
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    @media (max-width: $breakpoint-lg){
      margin-bottom: 30px;
    }
    &.--row-reverse{
      -webkit-flex-flow: row-reverse  wrap;
      flex-flow: row-reverse wrap;
      .teams-shop__content{
        padding-left: 15px;
      }
      .teams-shop__image-content{
        left: initial;
        right: 0;
        @media (max-width: $breakpoint-xl){
          right: initial;
        }
      }
      .teams-shop__image-caption-txt{
        margin-left: auto;
        margin-right: 0;
        @media (max-width: $breakpoint-lg){
          margin-left: 0;
          margin-right: auto;
        }
      }
    }
  }
  &__content{
    height: 440px;
    padding-right: 15px;
    @media (max-width: $breakpoint-xl){
      height: initial;
      padding: 30px 0;
    }
  }
  &__image-content{
    position: absolute;
    left: 0;
    width: 720px;
    height: 440px;
    @media (max-width: $breakpoint-xl){
      position: initial;
      left: initial;
      width: initial;
      height: initial;
      img{
        max-width: 100%;
      }
    }
  }
  &__image-caption{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #E7E7E6;
    padding: 25px 15px;
    @media (max-width: $breakpoint-xl){
      position: relative;
      bottom: 5px;
      left: initial;
      right: initial;
    }
    @media (max-width: $breakpoint-lg){
      max-width: 720px;
    }
  }
  &__image-caption-txt{
    max-width: 570px;
    font-size: 20px;
    font-weight: normal;
    line-height: 1.25;
    color: var(--col-primary);
    @media (max-width: $breakpoint-sm){
      font-size: 16px;
    }
  }
}

// new flickity style

// .carousel {
//   background: #FAFAFA;
//   margin-bottom: 40px;
// }

.carousel {

  &.mob {
    @media(min-width: $breakpoint-md) {
      display: none;
    }
  }
}

.btn-section {
  @media(min-width: 1200px) and (max-width: 1230px ) {
    display: -webkit-box;
  }
}
.mobile-full {
  @media(max-width: $breakpoint-sm) {
    width:100%;
    flex: 0 0 auto !important;
    max-width: 100% !important;
  }
}
// & .product_content {
//   @media(min-width: 1199px) and (max-width: 1400px ) {
//       left: 10px;
//       }
// }
