body #st-2 {
    left: 0;
    display:block;
    right:auto;
    top:260px;
    width: auto;
    & > div {
        float:left;
        clear:left;
    }
}