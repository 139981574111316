.addplayer-fields{
    margin-bottom: 60px;
    &__column{
      @include grid-size(calc(100%));
      @media (max-width: $breakpoint-md){
        @include grid-size(100%);
      }
    }
  }

 