@import "../tools/mixins";
@import "../tools/breakpoints";

.shopping-cart {
  &__head {
    margin-bottom: 40px;
    border-bottom: 1px solid #d8d8d8;
  }

  &__total {
    &-row {
      @media (max-width: $breakpoint-lg) {
        justify-content: initial;
        .row > span {
          text-align: left !important;
        }
      }
    }
    .btn {
      margin-left: auto;
      margin-right: 0;
      @media (max-width: $breakpoint-lg) {
        margin-left: 0;
        margin-right: auto;
      }
    }
  }

  &__btn {
    max-width: 170px;
    width: 100%;
    @media (max-width: $breakpoint-sm) {
      margin-top: 15px;
    }
  }

  &__remove {
    position: absolute;
    right: 30px;
    top: 0;
    font-size: 18px;
    cursor: pointer;
    color: var(--col-primary);
    @media (max-width: $breakpoint-lg) {
      right: initial;
      top: initial;
      bottom: 0;
      left: 15px;
    }
  }
  &__spin {
    // top: -10px;
    @media (max-width: $breakpoint-lg) {
      top: initial;
    }
  }

  &__content {
    border: 1px solid #d4d4d4;
    padding: 15px;
    margin-bottom: 35px;
  }
  &__block {
  }
  &__row {
    &.--body + &.--body {
      margin-top: 20px;
    }
    &.--head {
      margin-bottom: 20px;
      @media (max-width: $breakpoint-lg) {
        display: none;
      }
      span {
        font-weight: 600;
        color: var(--col-black);
      }
    }
    &.--body {
      position: relative;
      @media (max-width: $breakpoint-lg) {
        padding-bottom: 20px;
      }
    }
    span {
      display: block;
      font-size: 16px;
      font-weight: normal;
      line-height: 1.25;
      text-align: left;
      color: var(--col-grey);
    }
    .column {
      &:nth-child(1) {
        @include grid-size(16.66667%);
      }
      &:nth-child(2) {
        @include grid-size(14.99999%);
      }
      &:nth-child(3) {
        @include grid-size(26.66667%);
      }
      &:nth-child(4) {
        @include grid-size(8.33333%);
      }
      &:nth-child(5) {
        @include grid-size(16.66667%);
      }
      &:nth-child(6) {
        @include grid-size(16.66667%);
      }
      @media (max-width: $breakpoint-lg) {
        @include grid-size(100%!important);
        padding-left: 130px;
        position: relative;
        margin-bottom: 5px;
        &:before {
          content: attr(data-head) ": ";
          position: absolute;
          left: 15px;
          font-size: 16px;
          font-weight: 600;
          color: var(--col-black);
        }
      }
    }
  }
}

.checkout {
  padding: 0 0 55px 0;
}

.checkout-head {
  &__column {
    position: relative;
    margin-bottom: 50px;
    &:before {
      content: "";
      position: absolute;
      left: 50%;
      right: 0;
      top: 18px;
      height: 3px;
      width: 100%;
      z-index: -1;
      background-color: #d8d8d8;
    }
    &:last-of-type {
      &:before,
      &:after {
        display: none;
      }
    }
    &.active {
      &:after {
        content: "";
        position: absolute;
        left: 50%;
        right: 0;
        top: 18px;
        height: 3px;
        width: 50%;
        z-index: -1;
        background-color: var(--col-primary);
      }
      .checkout-head__step {
        background-color: var(--col-primary);
      }
      .checkout-head__title {
        color: var(--col-primary);
      }
    }

    &.active:nth-child(2) {
      &:after {
        left: 0;
        width: 100%;
      }
    }
    &.active:nth-child(3) {
      &:after {
        display: block !important;
        content: "";
        position: absolute;
        left: 0;
        width: 50%;
        height: 3px;
        background-color: var(--col-primary);
        @media (max-width: $breakpoint-sm) {
          display: none !important;
        }
      }
      &:before {
        content: "";
        position: absolute;
        left: 50%;
        right: 0;
        top: 18px;
        height: 3px;
        width: 100%;
        z-index: -1;
        background-color: var(--col-primary);
        @media (max-width: $breakpoint-sm) {
          display: none !important;
        }
      }
    }
    &.active.prev:nth-child(4) {
      &:before {
        content: "";
        position: absolute;
        left: 50%;
        right: 0;
        top: 18px;
        height: 3px;
        width: 100%;
        z-index: -1;
        background-color: var(--col-primary);
        @media (max-width: $breakpoint-sm) {
          display: none !important;
        }
      }
    }
    &.active:nth-child(4) {
      &:after {
        display: block !important;
        content: "";
        position: absolute;
        left: 0;
        width: 50%;
        height: 3px;
        background-color: var(--col-primary);
        @media (max-width: $breakpoint-sm) {
          display: none !important;
        }
      }
    }
    &.active:nth-child(5) {
      &:after {
        display: block !important;
        content: "";
        position: absolute;
        left: 0;
        width: 50%;
        height: 3px;
        background-color: var(--col-primary);
        @media (max-width: $breakpoint-sm) {
          display: none !important;
        }
      }
    }
    @media (max-width: $breakpoint-sm) {
      display: none;
      &:before,
      &:after {
        display: none !important;
      }
      &.active {
        display: block !important;
      }
    }
    &.prev {
      .checkout-head__step {
        border: 1px solid var(--col-primary);
        color: var(--col-primary);
        background-color: #fff;
      }
      .checkout-head__title {
        color: var(--col-grey);
      }
      @media (max-width: $breakpoint-sm) {
        display: none !important;
      }
    }
  }
  &__step {
    width: 40px;
    height: 40px;
    margin: auto auto 10px;
    border-radius: 50%;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    line-height: 40px;
    color: #fff;
    background-color: #d8d8d8;
  }
  &__title {
    display: block;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.39;
    color: #d8d8d8;
  }
}

.checkout-order {
  &__content {
    border: 1px solid #d4d4d4;
    padding: 15px 30px;
    margin-bottom: 40px;
  }
  &__block {
    padding-bottom: 15px;
    border-bottom: 1px solid #d4d4d4;
    margin-bottom: 15px;
  }
  &__row {
    &.--body + &.--body {
      margin-top: 15px;
    }
    &.--head {
      margin-bottom: 15px;
      @media (max-width: $breakpoint-md) {
        display: none;
      }
      span {
        font-weight: 600;
        color: var(--col-black);
      }
    }
    span {
      display: block;
      font-size: 16px;
      font-weight: normal;
      line-height: 1.25;
      text-align: left;
      color: var(--col-grey);
    }
    .column {
      &:nth-child(1) {
        @include grid-size(33.33333%);
      }
      &:nth-child(2) {
        @include grid-size(25%);
      }
      &:nth-child(3) {
        @include grid-size(8.33333%);
      }
      &:nth-child(4) {
        @include grid-size(16.66667%);
      }
      &:nth-child(5) {
        @include grid-size(16.66667%);
      }

      @media (max-width: $breakpoint-md) {
        @include grid-size(100%!important);
        padding-left: 110px;
        position: relative;
        margin-bottom: 5px;
        &:before {
          content: attr(data-head) ": ";
          position: absolute;
          left: 15px;
          font-size: 16px;
          font-weight: 600;
          color: var(--col-black);
        }
      }
    }
  }

  .checkout-total {
    margin-right: 90px;
    // padding-left: 50vw;
    @media (max-width: $breakpoint-xl) {
      margin-right: 0;
      padding-left: 0;
      .checkout-total__row {
        span:nth-child(1) {
          text-align: left;
        }
      }
    }
  }

  &__total-row {
    @media (max-width: $breakpoint-xl) {
      justify-content: left;
    }

    @media (max-width: $breakpoint-sm) {
      .checkout-total {
        // width: 165px;
        ul > li {
          span {
            // @include grid-size(95px);
          }
        }
      }
    }
  }
}

.checkout-total {
  width: 400px;
  @media (max-width: $breakpoint-md) {
    width: 225px;
  }
  &__row {
    span {
      display: block;
      font-size: 16px;
      font-weight: normal;
      line-height: 1.56;
      color: var(--col-grey);
    }
    span:nth-child(1) {
      @include grid-size(200px);
      text-align: left;
      font-weight: 600;
      color: var(--col-black);
      @media (max-width: $breakpoint-md) {
        @include grid-size(150px);
      }
    }
    span:nth-child(2) {
      // @include grid-size(100px);
      // text-align: left;
    }
  }
}

// checkout btn style

.checkout-btn {
  @media (max-width: $breakpoint-lg) {
    margin: 0 !important;
    padding-left: 0px;
  }
}


@media (max-width: $breakpoint-sm) {
  #payment-existing {
    display: none;
  }
  #shipping-existing {
    display: none;
  }
}

//updated shopping cart styles
#shopping-cart {
  border: 1px solid #d4d4d4;
  padding: 15px;
  margin-bottom: 35px;
  .cart-header {
    margin-bottom: 20px;
    @media (max-width: $breakpoint-lg) {
      display:none;
    }
    span {
      font-weight: 600;
      color:#000;
    }
  }
  .cart-product {
    padding-bottom:10px;
    margin-bottom:10px;
    &:last-child {
      margin-bottom:0;
      padding-bottom:0;
      border-bottom:0;
    }
    @media (max-width: $breakpoint-lg) {
      border-bottom: #ccc 1px solid;
    }
    .remove-link {
      min-height:18px;
      a {
        position:relative;
        left:0;
      }
    }
    & > div {
      @media (max-width: $breakpoint-lg) {
        display:flex;
        flex-direction:row;
        justify-content: flex-start;
        align-items: center;
        margin-bottom:10px;
      }
    }
    .mobile-title {
      display:none;
      font-weight: 600;
      color:#000;
      margin:3px 0;
      padding-right:10px;
      min-width:150px;
      @media (max-width: $breakpoint-lg) {
        display:block;
      }
    }
  }
}