@import "../tools/mixins";
@import "../tools/breakpoints";

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form {
  &.--modifier {
  }

  &__group {
    position: relative;
  }
  &__group-5 {
    margin-bottom: 5px;
    position: relative;
  }
  &__group-10 {
    margin-bottom: 10px;
    position: relative;
  }
  &__group-15 {
    margin-bottom: 15px;
    position: relative;
  }
  &__group-20 {
    margin-bottom: 20px;
    position: relative;
  }
  &__group-25 {
    margin-bottom: 25px;
    position: relative;
  }
  &__group-30 {
    margin-bottom: 30px;
    position: relative;
  }
  &__group-40 {
    margin-bottom: 40px;
    position: relative;
  }
  &__group-50 {
    margin-bottom: 50px;
    position: relative;
  }

  &__label {
    display: block;
    font-family: "Myriad Pro", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    text-align: left;
    color: var(--col-grey);
    margin-bottom: 10px;
    &-required {
      color: var(--col-primary);
    }
  }

  &__area {
    width: 100%;
    height: 150px;
    padding: 15px;
    border-radius: 3px;
    background-color: #fff;
    border: solid 1px var(--col-d4);
    outline: none;
    resize: none;
    font-family: "Myriad Pro", sans-serif;
    font-size: 16px;
    font-weight: normal;
    line-height: normal;
    color: var(--col-grey);
  }

  &__input,
  &__select {
    width: 100%;
    height: 40px;
    padding: 0 15px;
    border-radius: 3px;
    background-color: #fff;
    border: solid 1px var(--col-d4);
    outline: none;
    font-family: "Myriad Pro", sans-serif;
    font-size: 16px;
    font-weight: normal;
    line-height: normal;
    color: var(--col-grey);
  }
  &__select {
  }

  input:disabled {
    cursor: not-allowed;
  }
}

span.error-valid {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -16px;
  font-size: 12px;
  line-height: 14px;
  color: #e53539;
}

::-webkit-input-placeholder {
  font-family: "Myriad Pro", sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: normal;
  color: #a0a0a0;
}
::-moz-placeholder {
  font-family: "Myriad Pro", sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: normal;
  color: #a0a0a0;
}
:-ms-input-placeholder {
  font-family: "Myriad Pro", sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: normal;
  color: #a0a0a0;
}
:-moz-placeholder {
  font-family: "Myriad Pro", sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: normal;
  color: #a0a0a0;
}

.checkbox {
  position: relative;
  margin-bottom: 15px;
  label {
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    font-weight: normal;
    line-height: normal;
    color: var(--col-grey);
    user-select: none;
  }
}

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}

[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
}

[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
  content: "";
  position: absolute;
  width: 13px;
  height: 13px;
  top: 2px;
  left: 0;
  z-index: 5;
  border: 1px solid var(--col-grey);
  transition: border 0.25s linear;
  -webkit-transition: border 0.25s linear;
}

[type="checkbox"]:checked + label:after {
  display: block;
  position: absolute;
  content: "";
  width: 7px;
  height: 7px;
  top: 5px;
  left: 3px;
  margin: auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
  background-color: var(--col-primary);
}

[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}

[type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

[type="checkbox"]:disabled:not(:checked) + label:before,
[type="checkbox"]:disabled:checked + label:before {
  box-shadow: none;
  border-color: #d0d0d0;
  background-color: #d0d0d0;
}

[type="checkbox"]:disabled:checked + label:after {
  color: #fff;
}

[type="checkbox"]:disabled + label {
  //color: #aaa;
}

.check {
  display: block;
  position: absolute;
  width: 13px;
  height: 13px;
  top: 2px;
  left: 0;
  z-index: 1;
  border: 1px solid var(--col-grey);
  transition: border 0.25s linear;
  -webkit-transition: border 0.25s linear;
}

.check::before {
  display: block;
  position: absolute;
  content: "";
  width: 7px;
  height: 7px;
  top: 2px;
  left: 2px;
  margin: auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
}

.radio__form {
  position: relative;
  margin-bottom: 15px;
  label {
    display: inline-block;
    padding-left: 25px;
    font-size: 16px;
    cursor: pointer;
    font-weight: normal;
    line-height: normal;
    color: var(--col-grey);
    user-select: none;
    position: relative;
    z-index: 2;
  }
  input[type="radio"] {
    left: 0;
    margin: 0;
    top: 5px;
    width: 13px;
    height: 13px;
    visibility: hidden;
    position: absolute;
  }
}

input[type="radio"]:checked ~ .check::before {
  background: var(--col-primary);
}
input[type="radio"]:checked ~ label {
}

.spin {
  position: relative;
  width: 70px;
  background-color: #fff;
  &__input {
    width: 50px;
    height: 40px;
    border-radius: 3px;
    font-size: 16px;
    font-weight: normal;
    line-height: normal;
    color: var(--col-grey);
    padding: 0 25px 0 15px;
    border: solid 1px #d4d4d4;
    background-color: #fff;
  }
  &__button {
    position: absolute;
    border: solid 1px #d4d4d4;
    width: 20px;
    height: 20px;
    right: 0;
    padding: 0;
    outline: none;
    cursor: pointer;
    font-size: 7px;
    color: var(--col-black);
    background-color: #f4f4f4;
    @include transition;
    &:active {
      background-color: var(--col-grey);
    }
    & + & {
      border-top: 0;
    }
    &[disabled] {
      opacity: 0.5;
    }
    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &__button-plus {
    top: 0;
    border-top-right-radius: 3px;
  }
  &__button-minus {
    bottom: 0;
    border-bottom-right-radius: 3px;
    &:before {
      transform: translate(-50%, -50%) scale(-1);
    }
  }
}

//  textarea height changed from default in form_input
textarea.form__input.form-control {
  height: auto;
}
