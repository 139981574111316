@import "../tools/mixins";
@import "../tools/breakpoints";

.flickity-enabled {
  position: relative;
  z-index: 1;
  &:focus {
    outline: none;
  }
  &.is-draggable {
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;

  @media (max-width: $breakpoint-sm) {
    height: calc(100vh - 300px);
  }

}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.flickity-prev-next-button {
  position: absolute;
  top: 50%;
  width: 30px;
  height: 50px;
  cursor: pointer;
  border: 0;
  font-size: 35px;
  color: var(--col-black);
  outline: none;
  background-color: transparent;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: all 0.3s ease;
  @media (max-width: $breakpoint-sm){
    height: 30px;
  }
}

.flickity-prev-next-button:focus {
  outline: none;
}

.flickity-prev-next-button.previous {
  left: 0;
  &:after{
    content: "\e911";
    font-family: 'icomoon' !important;
  }
}


.flickity-prev-next-button.next {
  right: 0;
  &:after{
    content: "\e912";
    font-family: 'icomoon' !important;
  }
}




.flickity-prev-next-button {
  svg {
    display: none;
  }
}

.flickity-rtl .flickity-prev-next-button.previous {
  //left: auto;
  //right: 10px;
}

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}


.flickity-prev-next-button svg {
  //position: absolute;
  //left: 0;
  //top: 0;
  //width: 60%;
  //height: 60%;
}

.flickity-prev-next-button .arrow {
  //fill: #77c97b;
}

.flickity-page-dots {
  position: absolute;
  max-width: 1110px;
  margin: auto;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 30px;
  z-index: 10;
  padding: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
  transition: all 0.3s ease;
  .dot {
    position: relative;
    width: 170px;
    height: 5px;
    display: inline-block;
    cursor: pointer;
    background-color: #fff;
    @include transition;
    &:hover{
      background-color: var(--col-primary);
    }
    @media (max-width: $breakpoint-lg) {
      width: 150px;
    }
    @media (max-width: $breakpoint-md) {
      width: 90px;
    }
    @media (max-width: $breakpoint-sm) {
      width: 45px;
    }
  }
  .dot + .dot {
    margin-left: 30px;
  }
  .dot.is-selected {
    background-color: var(--col-primary)
  }
}
