@import "../tools/mixins";
@import "../tools/breakpoints";


.home-screen{
  margin-top: 135px;
  // height: calc(100vh - 135px);
  height: calc(100vh - 335px);
  background-color: #414141;
  @media (max-width: $breakpoint-lg){
    margin-top: 100px;
    height: calc(100vh - 100px);
  }
  @media (max-width: 655px){
    margin-top: 90px;
  }

  @media (max-width: $breakpoint-sm) {

    height: calc(100vh - 300px);
  }
  
  &__carousel{  }
  &__cell{
    width: 100%;
    height: calc(100vh - 335px);
    background: center no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    @media (max-width: $breakpoint-lg){
      height: calc(100vh - 100px);
    }
    @media (max-width: $breakpoint-sm) {
      height: calc(100vh - 300px);
    }
  }
  &__content{
    cursor: initial;
    // margin-left: 60%;
    // margin-bottom: 65px;
    @media (max-width: $breakpoint-xl){
     // margin-left: 50%;
    }
    @media (max-width: $breakpoint-md){
     // margin-left: 0;
    // margin: auto;
      //text-align: center;
      .btn{
        margin: auto;
      }
    }

    &.mob-view {
      @media (max-width: $breakpoint-sm) {
      }
    }

    &.cloned {
      overflow: hidden;
       z-index: 99;
       height: 260px;
       margin-top: 15px;

      //  &.has-subtitle {

      //     & .home-screen {
      //       &__subtitle {
      //         height: 30px;
      //       }

      //       &__title {
      //         height: 60px;
      //       }
      //     }
      //  }
    }
    
  }
  &__subtitle{
    display: block;
    font-size: 36px;
    font-weight: normal;
    line-height: normal;
    color: var(--col-black);
    margin-bottom: 8px;
    margin-right: 10px;
    @media (max-width: $breakpoint-md){
      font-size: 30px;
      margin: 0 auto;
      text-align: center;
    }

  }
  &__title{
    font-size: 60px;
    font-weight: normal;
    line-height: 1;
    color: #fff;
    margin-bottom: 25px;
    margin-right: 10px;
    @media (max-width: $breakpoint-md){
      font-size: 45px;
      text-align: center;
        }
    strong{
      font-weight: bold;
    }
  }

  .flickity-prev-next-button{
    color: rgba(255,255,255,0.5);
    font-size: 50px;
    @media (max-width: $breakpoint-sm) {
      font-size: 30px;
    }
  }
  .flickity-prev-next-button.previous {
    left: 30px;
    @media (max-width: $breakpoint-md) {
      left: 15px;
    }
  }
  .flickity-prev-next-button.next {
    right: 30px;
    @media (max-width: $breakpoint-md) {
      right: 15px;
    }
  }


}



.home-about{
  background: url(/image/data/background/who-we-are-homepage.jpg) center no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  // &__overlay{
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   opacity: 0.85;
  //   background-blend-mode: darken;
  //   background-image: linear-gradient(rgba(17, 22, 38, 0.75), rgba(17, 22, 38, 0.75));
  // }
}



.home-column{
  margin-bottom: 30px;
  &.--links{
    @media (max-width: 475px){
      @include grid-size(100%);
    }
  }
  &:hover{
    .home-column__title{
      color: #fff;
      z-index: 1;
      &:after{
        content: '';
        position: absolute;
        top: -10px;
        left: -15px;
        right: -15px;
        bottom: -10px;
        z-index: -1;
        background-color: var(--col-primary);
      }
    }
  }
  &.--modifier{
    margin-bottom: 50px;
  }
  &__block{
    height: 100%;
    // max-width: 270px;
    background-color: #fff;
    @include transition;
    &.--type-2{
      max-width: 350px;
      @media (max-width: $breakpoint-sm){
        margin: auto;
      }
    }
    &.--link{
      margin: auto;
    }

    &.quick-links {
      max-width: 350px;
    }
  }
  &__head{
    overflow: hidden;
    img {
      width:100%;
    }
  }
  &__image{
    &.quick-links {
      width: 350px;
    }
  }
  &__content{
    padding: 10px 15px 30px 15px;
    background-color: #fff;

    &.quick-links {
      max-width: 350px;
    }
  }
  &__title{
    position: relative;
    display: block;
    font-family: 'Myriad Pro', sans-serif;
    font-size: 22px;
    line-height: normal;
    color: var(--col-black);
    margin-bottom: 15px;
    font-weight: bold;
  }
  &__text{
    color: #a0a0a0;
  }
  &__{}
}


.home-skill-center{
  background: url(../img/background/home-skill-center.png) center no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  @media (max-width: $breakpoint-sm){
    padding: 60px 0 20px;
  }
  &__video{
    max-width: 570px;
    width: 100%;
    height: 315px;
    display: block;
    margin: auto;
    @media (max-width: $breakpoint-lg){
      margin: 30px auto auto;
    }
    @media (max-width: $breakpoint-sm){
      margin: auto auto 30px;
    }
  }

  @media (max-width: $breakpoint-sm){
    .column:nth-child(1){
      @include order(2);
    }
    .column:nth-child(2){
      @include order(1);
    }
    .btn{
      width: 150px;
    }
  }
  @media (max-width: 374px){
    .btn{
      width: 130px;
    }
  }
}

.home-tournaments{
  background: url(../img/background/home-tournaments.png) center no-repeat;
  -webkit-background-size: cover;
  background-size: cover;

  @media (max-width: $breakpoint-sm) {
     padding-bottom: 25px;
  }

  @media (max-width: $breakpoint-lg){
    img{
      margin-bottom: 30px;
    }
  }
}


.home-form{
  position: relative;
  max-width: 570px;
  &__input{
    border: 0;
    padding-right: 180px;
    @media (max-width: $breakpoint-sm){
      padding-right: 125px;
    }
  }
  &__btn{
    position: absolute;
    right: 0;
    top: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    @media (max-width: $breakpoint-sm){
      width: 120px;
    }
  }
}

.cloned {
  @media (min-width: $breakpoint-md) {
    display: none;
  }

  & .cloned-btn-home {
      margin-bottom: 20px;
  }
}

.desktop-slider-content {
  @media (max-width: $breakpoint-md) {
    display: none;
  }
}

// app- training home page

.app-training__content {
  width: 350px;

  .app-training__overlay {
    // width: 350px;
    background-color: #111626;
    height: 50px;

      &-title {
        position: relative;
        display: block;
        font-family: 'Myriad Pro', sans-serif;
        font-size: 22px;
        line-height: normal;
        color: #fff;
        padding-top: 10px;
        margin-bottom: 10px;
        font-weight: bold;
        margin-left: 12px;
      }

      &:hover {
        background-color: var(--col-primary);
      }

      @media (max-width: $breakpoint-sm) {
        width: 345px;
        margin-bottom: 50px;
      }
  }

}

.bronko-belt-home {
  @media(max-width: $breakpoint-lg) {
    margin-bottom: 30px;
  }
}

.mob-title-wrapper {
  height: 100px;
}

.start-training {
  @media(max-width: $breakpoint-md) {
    padding-bottom: 10px!important;
  }
}

.camps-screen__cell{
  width: 100%;
  height: 90vh;
  margin-top: 8rem;
  position: relative;
  overflow: hidden;
  @media (max-width: $breakpoint-lg) {
    margin-top: 6rem;
  }
  @media (max-width: $breakpoint-sm) {
    height: 50vh;
    margin-top: 5.5rem;
  }
}

