@mixin bold() {
  font-family: var(--font-family-bold);
}

@mixin title($color,$size) {
  display: block;
  color: $color;
  font-size: $size;
  font-family: var(--font-family-900);
}

@mixin transition() {
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

@mixin grid-size($size) {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 $size;
  -ms-flex: 0 0 $size;
  flex: 0 0 $size;
  max-width: $size;
}

@mixin order($number) {
  order: $number;
}

@mixin text-ellipsis($strokes) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $strokes;
  -webkit-box-orient: vertical;
}