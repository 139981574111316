@import "../tools/mixins";
@import "../tools/breakpoints";

/* NOTIFICATIONS */


#notification{
  position: fixed;
  width: 450px;
  height: auto;
  right: 30px;
  top: 150px;
  bottom: auto;
  //margin-left: -300px;
  z-index: 9999;

  @media (max-width: $breakpoint-sm) {
    width: 310px;
    height: auto;
    top: 50px;
  }
}
.success, .warning, .attention, .information {
  padding: 15px 10px 15px 30px;
  color: #555555;
  -webkit-border-radius: 5px 5px 5px 5px;
  -moz-border-radius: 5px 5px 5px 5px;
  -khtml-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}
.success {
  background: #EAF7D9 url('../img/success.png') 10px center no-repeat;
  border: 6px solid #BBDF8D;
}
.warning {
  background: #FFD1D1 url('../img/warning.png') 10px center no-repeat;
  border: 1px solid #F8ACAC;
}
.attention {
  background: #FFF5CC url('../img/attention.png') 10px center no-repeat;
  border: 1px solid #F2DD8C;
}
.success .close, .warning .close, .attention .close, .information .close {
  float: right;
  padding-top: 4px;
  padding-right: 4px;
  padding-left: 10px;
  cursor: pointer;
}
.required {
  color: #FF0000;
  font-weight: bold;
}
.error {
  display: block;
  color: #FF0000;
}
.help {
  color: #999;
  font-size: 10px;
  font-weight: normal;
  font-family: Verdana, Geneva, sans-serif;
  display: block;
}

.ui-datepicker-month, .ui-datepicker-year {
  color: black;
}
