@font-face {
  font-family: 'Myriad Pro';
  src: url('../fonts/MyriadPro-Light.woff2') format('woff2'),
  url('../fonts/MyriadPro-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('../fonts/MyriadPro-Regular.woff2') format('woff2'),
  url('../fonts/MyriadPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('../fonts/MyriadPro-Semibold.woff2') format('woff2'),
  url('../fonts/MyriadPro-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('../fonts/MyriadPro-Bold.woff2') format('woff2'),
  url('../fonts/MyriadPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('../fonts/MyriadPro-Black.woff2') format('woff2'),
  url('../fonts/MyriadPro-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?p8oy2g');
  src:  url('../fonts/icomoon.eot?p8oy2g#iefix') format('embedded-opentype'),
  url('../fonts/icomoon.ttf?p8oy2g') format('truetype'),
  url('../fonts/icomoon.woff?p8oy2g') format('woff'),
  url('../fonts/icomoon.svg?p8oy2g#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}
@import "../tools/fontawesome/fontawesome.scss";
@import "../tools/fontawesome/solid.scss";
@import "../tools/fontawesome/brands.scss";