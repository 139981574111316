@import "../tools/mixins";
@import "../tools/breakpoints";


.btn{
  position: relative;
  display: block;
  outline: none;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  border: 0;
  background-color: transparent;
  backface-visibility: hidden;
}

.btn-primary{
  width: 170px;
  padding: 0;
  height: 40px;
  border-radius: 4px;
  background-color: var(--col-primary);
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  line-height: 40px;
  font-family: 'Myriad Pro', sans-serif;
  font-size: 16px;
  font-weight: bold!important;
  color: #fff;
  @include transition;
  &.--black{
    background-color: var(--col-black);
  }
  &.--grey{
    background-color: var(--col-grey);
  }
  &:hover{
    background-color: var(--col-primary);
    box-shadow: 0 0 13px rgba(78, 78, 78, 0.2);
  }
  &.btn-sm {
    width: auto;
    height: 25px;
    font-size: 14px;
    line-height: 25px;
    padding: 0 10px;
    margin: 0;
    display: inline-block;
  }
}

.btn-share{
  width: 170px;
  padding: 0;
  height: 40px;
  border-radius: 4px;
  background-color: #fff;
  border: solid 1px #d4d4d4;
  cursor: pointer;
  text-align: center;
  line-height: 40px;
  font-family: 'Myriad Pro', sans-serif;
  font-size: 16px;
  font-weight: bold!important;
  color: var(--col-black);
  @include transition;
  i{
    position: relative;
    margin-right: 10px;
    top: 2px;
  }
  &:hover{
    box-shadow: 0 0 13px rgba(78, 78, 78, 0.2);
    @media (max-width: $breakpoint-sm){
      box-shadow: none;
    }
  }
  &.mob {
    @media (max-width: $breakpoint-md){
      border: none;
      background-color: transparent;
      line-height: 0px;
    }
    width: 0px;
    padding: 0;
    height: 0px;
    border-radius: 0px;
    cursor: pointer;
    text-align: center;
  }

  &:active {
    border: none;
    background-color: transparent;
    line-height: 0px;
    box-shadow: none;
  }

}



.btn-link{
  display: inline-block;
  position: relative;
  padding-right: 20px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold!important;
  line-height: normal;
  color: var(--col-primary);
  &.--grey{
    color: #a0a0a0;
    &:after{
      color: #a0a0a0;
    }
  }
  &:after{
    content: "\e915";
    font-family: 'icomoon' !important;
    position: absolute;
    top: 1px;
    right: 0;
    font-weight: bold!important;
    font-size: 14px;
    color: var(--col-primary);
    transition: all 0.25s ease;
  }
  &:hover{

    color: var(--col-primary);
    text-decoration: none;

    &:after{
      transform: translateX(5px);
    }
  }
  &.--left{
    padding-left: 20px;
    padding-right: 0;
    &:after{
      left: 0;
      right: initial;
      transform: scale(-1);
    }
    &:hover{
      &:after{
        transform: translateX(-5px) scale(-1);
      }
    }
  }
  &.--plus{
    &:after{
      content: "\e91e";
      font-size: 26px;
      top: 50%;
      transform: rotate(0) translateY(-50%);
      left: -4px;
    }
    &:hover{
      &:after{
        transform: translateY(-50%) rotate(90deg);
      }
    }
  }
}

.btn-right{
  margin-right: 0;
  margin-left: auto;
}

// drop down btn style for camp page

.dropdown.bootstrap-select {
  background-color: var(--col-black);
  color: #fff;
  border-radius: 4px;
}

button.btn.dropdown-toggle.bs-placeholder.btn-light {
  background-color: var(--col-black);
  color: #fff;
  font-weight: bold;
  height: 40px;
}

button.btn.dropdown-toggle.btn-light {
  height: 40px;
}

.btn-light:hover {
    color: #fff;
    background-color: var(--col-black);
    // border-color: #dae0e5;
}

.btn-light:not(:disabled):not(.disabled):active, .show>.btn-light.dropdown-toggle {
  color: #fff;
  background-color: var(--col-grey);
  // border-color: #d3d9df;
}

.dropdown-menu.show {
  background-color: var(--col-black);
  margin: 0;
}

a.select-option.dropdown-item {
  color: #fff;
  text-align: center;
}

.dropdown-item:focus, .dropdown-item:hover {
  color: #fff;
  text-decoration: none;
  background-color: var(--col-grey);
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: var(--col-grey);
  outline: none!important;
}

a.select-option.dropdown-item.selected.active {
  background-color: var(--col-grey);
}

.bootstrap-select .dropdown-toggle:focus {
  outline: black!important;
  background-color: var(--col-black);
  color: #fff;
}

.show>.btn-light.dropdown-toggle {
  color: #fff;
  background-color: var(--col-black);
}

.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: 170px!important;
  height: 40px;
  margin: 20px;

  @media (max-width: $breakpoint-sm) {
    margin: 15px;
  }
}

.btn.btn-primary.col-lg-3.col-sm-12.m-auto.--black.add_player {
  margin-bottom: 20px!important;
 
}

.dropdown.bootstrap-select.m-auto.mb-20 {
  margin-bottom: 20px!important;
}

button.btn.dropdown-toggle.btn-light {
  color: #fff;
}

.btn-light:focus {
  box-shadow: none!important;
}

.filter-option-inner-inner {
  text-align: center;
  padding-top: 2px;
}

// camp add to cart btn

.camp-add-div {
  right: 15px;
  @media (max-width: $breakpoint-sm) {
    margin-left: 0px;
    right: 0;
  }

  & .camp-add-btn {
    width: 174px!important;
  }
}

.add_player {
  max-width: 29%!important;

  @media (min-width: 575px ) and (max-width: 990px) {
   margin-left: 15px;
   max-width: 20%!important;
  }
  @media (max-width: 889px) {
    margin-left: 15px;
    max-width: 50%!important;
  }
}

// playlist save btn 

.playlist-save {
  @media (min-width: $breakpoint-md) {
    text-align: right;
  }

  @media (max-width: $breakpoint-md) {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

// search page results

.dropdown.bootstrap-select.show-results {
  width: 180px!important;
}

.dropdown.bootstrap-select.show-results.show {
  width: 180px!important;
}

.sort {
  right: 30px;
  @media (max-width: $breakpoint-md) {
    right: 0;
  }
}

.share-article--mob {
  display: none;
}

@media (max-width: $breakpoint-sm) {
  .share-article--mob {
    display: block;
  }
  .share-article {
    display: none;
  }
  
}






