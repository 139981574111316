@import "../tools/mixins";
@import "../tools/breakpoints";

.black {
  color:var(--col-black) !important;
}
.red {
  color:var(--col-primary) !important;
}
.grey {
  color:var(--col-grey) !important;
}
.light-grey {
  color:var(--col-light-grey) !important;
}
.grey-d4 {
  color:var(--col-d4) !important;
}
.white {
  color:var(--col-white) !important;
}

.font-regular {
  color:var(--font-regular) !important;
}
.font-medium {
  color:var(--font-medium) !important;
}
.font-bold {
  color:var(--font-bold) !important;
}
