@import "tools/_variables";

@import "base/_colors";
@import "base/_reset";
@import "base/_fonts";

@import "base/_grid";
@import "base/_base";

@import "modules/_btn";
@import "modules/_icon";
@import "modules/_modal";
@import "modules/_keyframes";

@import "plugins/_flickity";
@import "plugins/_tooltips";
@import "plugins/_perfectscroll";

@import "layout/_form";
@import "layout/_header";
@import "layout/_footer";
@import "layout/_layout";
@import "layout/_notification";

@import "pages/_home";
@import "pages/_shop";
@import "pages/_train";
@import "pages/_about";
@import "pages/_addPlayer";
@import "pages/_orderview";
@import "pages/_orderlist";

@import "pages/_checkout";
@import "pages/_dashboard";
@import "tools/_socialbar";
