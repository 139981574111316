
// Font Awesome Icon style


.autoplay-toggle {
// display:block;


    & .track {

        .handle {
            border-radius: 27px;
            box-shadow: 0 2px 7px rgba(0, 0, 0, 0.35), 0 1px 1px rgba(0, 0, 0, 0.15);
            display: block;
            height: 16px;
            left: 7px;
            position: inherit;
            top: 7px;
            transition-property: background-color, -webkit-transform;
            transition-property: background-color, transform;
            transition: 0.3s cubic-bezier(0, 1.1, 1, 1.1);
            width: 16px;

        }
    }

    input:checked {

        & + .track {
            background-color: green;
            border-color: green;
            border-radius: 20px;
            border: solid 2px #e6e6e6;
            box-sizing: border-box;
            content: ' ';
            cursor: pointer;
            display: inline-block;
            height: 20px;
            pointer-events: none;
            transition-duration: 0.3s;
            transition-property: background-color, border;
            transition-timing-function: ease-in-out;
            width: 40px;

            & .handle {
                background-color: #ffffff;
                transform: translate3d(20px, 0, 0);
                
            }
        }
    }
}