@import "../tools/mixins";
@import "../tools/breakpoints";

.app-head-regular {
  position: relative;
  margin-top: 135px;
  background: center no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  @media (max-width: $breakpoint-lg) {
    margin-top: 100px;
  }
  @media (max-width: 655px) {
    margin-top: 90px;
  }
  &__overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    // background-image: linear-gradient(to left, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5));
    &.--modifier {
      // background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5));
    }
  }
  &__content {
    position: relative;
    height: 455px;
    z-index: 3;
  }
  &__txt {
    display: block;
    font-size: 20px;
    font-weight: normal;
    line-height: 1.05;
    color: #fff;
  }

  &.bronko-belt {
    height: 550px;
  }
}
.no-header-margin .app-head-regular {
  margin-top: 0 !important;
}

.app-head-sm {
  margin-top: 135px;
  height: 70px;
  // background: url(../img/background/small-head-bg.png) center no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  @media (max-width: $breakpoint-lg) {
    margin-top: 100px;
  }
  @media (max-width: 655px) {
    margin-top: 50px;
    height: 39px;
  }
}

.breadcrumbs {
  position: relative;
  &__list {
  }
  &__item {
    position: relative;
    display: inline-block;
  }
  &__item + &__item {
    padding-left: 15px;
  }
  &__item + &__item:before {
    content: ">";
    position: absolute;
    font-size: 16px;
    font-weight: 600;
    left: 1px;
    top: -1px;
    color: #717171;
  }
  &__link {
    display: block;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    color: #717171;
    @include transition;
    &:hover {
      color: rgba(255, 0, 0, 1);
    }
    // &.active:hover {
    //   color: rgba(255, 255, 255, 0.65);
    // }
  }
  @media (max-width: $breakpoint-sm) {
    display: none;
  }
}
@media (max-width: $breakpoint-sm) {
  .display-table__cell:has(div.breadcrumbs) {
    display: none;
  }
}

.camp-column {
  margin-bottom: 30px;
  &.--modifier {
    margin-bottom: 50px;
  }
  &__head{
    overflow: hidden;
    img {
      width:100%;
    }
  }
  &:hover {
    .camp-column__block {
      .camp-column__title {
        color: #fff;
        z-index: 1;
        &:after {
          content: "";
          position: absolute;
          top: -11px;
          left: -15px;
          right: -15px;
          bottom: -10px;
          z-index: -1;
          background-color: var(--col-primary);
        }
      }
    }
  }
  &.active {
    .camp-column__title {
      color: #fff;
      z-index: 1;
      &:after {
        content: "";
        position: absolute;
        top: -11px;
        left: -15px;
        right: -15px;
        bottom: -10px;
        z-index: -1;
        background-color: var(--col-primary);
      }
    }
  }
  &__block {
    height: 100%;
    // max-width: 350px;
    background-color: #fff;
    @include transition;
    @media (max-width: $breakpoint-sm) {
      margin: auto;
    }
  }
  &__content {
    padding: 10px 15px 0px 15px;
    background-color: #fff;
  }
  &__title {
    position: relative;
    display: block;
    font-family: "Myriad Pro", sans-serif;
    font-size: 22px;
    font-weight: bold;
    line-height: normal;
    color: var(--col-black);
    margin-bottom: 15px;
  }
  &__text {
    @include text-ellipsis(4);
    a:hover {
      color: #717171;
    }
  }
}

.page-column {
  &__column {
    max-width: 380px;
    margin-bottom: 30px;
    @media (max-width: $breakpoint-sm) {
      margin: auto auto 30px;
    }
  }
  &__content {
    position: relative;
    @include transition;

    &:hover {
      .page-column__overlay {
        background-color: var(--col-primary);
      }
      .page-column__overlay-title {
        color: #fff;
      }
    }
  }
  &__overlay {
    position: relative;
    top: -1px;
    background-color: #fff;
    @include transition;
    &.--black {
      background-color: var(--col-black);
      span {
        color: #fff;
      }
    }

    &.training-home {
      background-color: #111626;
      height: 50px;
    }
  }
  &__overlay-title {
    display: block;
    padding-left: 15px;
    font-size: 22px;
    font-weight: bold;
    line-height: 45px;
    //color: #fff;
    line-height: 1.2;
    padding-top:7px;
    padding-bottom:7px;
    color: var(--col-black);
    &.--small {
      font-size: 20px;
    }
    @media (max-width: $breakpoint-lg) {
      font-size: 16px;
    }

    &.training-home {
      color: #fff;
    }
  }
}

.app-testimonials {
  position: relative;
  &__carousel {
    padding: 0 100px;
    margin-bottom: 50px;
    @media (max-width: $breakpoint-lg) {
      padding: 0 50px;
    }
    @media (max-width: $breakpoint-md) {
      padding: 0 30px;
    }
    .flickity-prev-next-button {
      color: #d4d4d4;
      @media (max-width: $breakpoint-sm) {
        font-size: 30px;
      }
    }
    .flickity-prev-next-button.previous {
      left: 0;
      @media (max-width: $breakpoint-md) {
        left: -10px;
      }
    }
    .flickity-prev-next-button.next {
      right: 0;
      @media (max-width: $breakpoint-md) {
        right: -10px;
      }
    }
  }
  &__cell {
    width: 50%;
    @media (max-width: $breakpoint-md) {
      width: 100%;
    }
  }
  &__image-column {
    @include grid-size(100px);
  }

  &__content-column {
    @include grid-size(calc(100% - 100px));
  }
  &__image {
    max-width: 70px;
    border-radius: 50%;
  }
  &__title {
    display: block;
    font-size: 20px;
    font-weight: bold;
    line-height: normal;
    color: var(--col-black);
  }
  &__subtitle {
    display: block;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.56;
    color: var(--col-grey);
    margin-bottom: 20px;
  }
}

.page-tabs {
  &__list {
    border-bottom: 1px solid #d8d8d8;
  }
  &__list-item {
    display: inline-block;
    @media (max-width: $breakpoint-md) {
      width: 49%;
      margin-bottom: 15px;
      text-align: center;
    }
    @media (max-width: $breakpoint-sm) {
      width: 100%;
    }
    & + & {
      margin-left: 70px;
      @media (max-width: $breakpoint-xl) {
        margin-left: 45px;
      }
      @media (max-width: $breakpoint-lg) {
        margin-left: 20px;
      }
      @media (max-width: $breakpoint-md) {
        margin-left: 0;
      }
    }
  }
  &__list-link {
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    color: #a0a0a0;
    padding-bottom: 5px;
    @include transition;
    @media (max-width: $breakpoint-lg) {
      font-size: 16px;
    }
    @media (max-width: $breakpoint-md) {
      text-align: center;
    }
    &.active {
      color: var(--col-black);
      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 3px;
        background-color: var(--col-black);
      }
    }
    &:hover {
      color: var(--col-black);
    }
  }

  &__content {
    padding-top: 40px;
  }
}

.v-section {
  &__video {
    width: 100%;
    height: 460px;
    @media (max-width: $breakpoint-lg) {
      height: 400px;
    }
  }
}

.tab-content {
  display: none;
  animation: fadeEffect 0.5s;
  -webkit-animation: fadeEffect 0.5s;
  &.active {
    display: block;
  }
}

.faq {
  &__block {
    border-bottom: 1px solid #eef0f2;
    &.js-active {
      .faq__icon {
        &:after {
          top: 100%;
        }
      }
    }
  }
  &__head {
    position: relative;
    padding: 15px 20px 15px 0;
  }
  &__icon {
    position: absolute;
    right: 0;
    top: 20px;
    overflow: hidden;
    width: 16px;
    height: 16px;
    &:before,
    &:after {
      content: "";
      position: absolute;
      background-color: var(--col-primary);
      @include transition;
    }
    &:before {
      height: 2px;
      left: 0;
      right: 0;
      top: 6px;
    }
    &:after {
      height: 100%;
      width: 2px;
      bottom: 0;
      top: 0;
      left: 7px;
    }
  }
  &__content {
    padding-bottom: 20px;
    .app-txt + .app-txt {
      margin-top: 10px;
    }
  }
  &__title {
    display: block;
    font-family: "Myriad Pro", sans-serif;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
    line-height: normal;
    color: var(--col-black);
    @media (max-width: $breakpoint-md) {
      font-size: 18px;
    }
    @media (max-width: $breakpoint-sm) {
      font-size: 16px;
    }
  }
}

.page-accordion {
  &__block {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
    &.js-active {
      .page-accordion__toggle-icon {
        &:after {
          top: 100%;
        }
      }
      .page-accordion__content {
        display: block;
      }
    }
  }
  &__head {
    position: relative;
    padding: 5px 20px 5px 40px;
    border-bottom: 1px solid var(--col-primary);
  }
  &__icon {
    position: absolute;
    left: 0;
    top: 12px;
    font-size: 30px;
    color: var(--col-black);
    @media (max-width: $breakpoint-md) {
      top: 3px;
    }
    @media (max-width: $breakpoint-sm) {
      font-size: 24px;
      top: 6px;
    }
  }
  &__toggle-icon {
    position: absolute;
    right: 0;
    top: 20px;
    overflow: hidden;
    width: 16px;
    height: 16px;
    &:before,
    &:after {
      content: "";
      position: absolute;
      background-color: var(--col-primary);
      @include transition;
    }
    &:before {
      height: 2px;
      left: 0;
      right: 0;
      top: 7px;
    }
    &:after {
      height: 100%;
      width: 2px;
      bottom: 0;
      top: 0;
      left: 7px;
    }

    @media (max-width: $breakpoint-md) {
      top: 12px;
    }
  }
  &__content {
    padding-top: 40px;
    .app-txt + .app-txt {
      margin-top: 10px;
    }
    img {
      @media (max-width: $breakpoint-md) {
        margin-top: 30px;
      }
    }
  }
  &__video {
    height: 205px;
    width: 100%;
    max-width: 400px;
    @media (max-width: $breakpoint-md) {
      margin: 30px 0;
    }
  }
  &__title {
    display: block;
    font-size: 36px;
    font-weight: bold;
    line-height: normal;
    color: var(--col-black);
    @media (max-width: $breakpoint-md) {
      font-size: 24px;
    }
    @media (max-width: $breakpoint-sm) {
      font-size: 20px;
    }
  }
}
.p-right-none {
  padding-right: 0;
}
.p-left-none {
  padding-left: 0;
}

.banner-content-div {
  @media (max-width: $breakpoint-sm) {
    margin-left: 0 !important;
    margin-bottom: 100px;
    position: absolute;
  }
}

.section-to-print {
  display: none;
}

.testimonial-mob {
  display: none;
}

@media (max-width: $breakpoint-sm) {
  .testimonial-mob {
    display: block;
  }
  .testimonial-desktop {
    display: none;
  }
}
.lineheight-normal {
  line-height: normal;
}
.show-mobile {
  display:none;
}
@media (max-width: $breakpoint-md) {
  .show-mobile {
    display:block;
  }
  .show-desctop {
    display:none;
  }
  .show-desctop-flex {
    display:none;
  }
}
@media (min-width: $breakpoint-md) {
  .show-mobile {
    display:none;
  }
  .show-desctop {
    display:block;
  }
  .show-desctop-flex {
    display:flex;
  }
}
.collapse {
  display: none !important;
  &.show {
    display:block !important;
  }
}