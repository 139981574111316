@import "../tools/mixins";
@import "../tools/breakpoints";


.our-instructors{
  &__title{}
  &__column{
    &.active{
      a{
        pointer-events: none;
      }
      img{
        opacity: 0.6;
      }
      .our-instructors__name{
        color: var(--col-grey);
      }
    }
  }
  &__desc{
    margin-bottom: 80px;
    @media (max-width: $breakpoint-sm){
      margin-bottom: 45px;
    }
    .app-txt + .app-txt {
      margin-top: 10px;
    }
  }
  &__content{
    background-color: #fff;
    margin-bottom: 30px;
    backface-visibility: hidden;
    @include transition;

    img{
      backface-visibility: hidden;
      @include transition;
      margin: 0 auto;
    }
    &:hover{
      img{
        opacity: 0.6;
      }
    }


  }
  &__name{
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-size: 28px;
    font-weight: bold;
    line-height: normal;
    color: var(--col-primary);
    @include transition;
    margin: 15px 0 5px 0;
  }
  &__role{
    display: block;
    text-align: center;
    font-size: 20px;
    font-weight: normal;
    line-height: normal;
    color: var(--col-grey);
  }

}


.instructor{
  &__column{
    margin-bottom: 30px;
    @media (max-width: $breakpoint-xl){
      order: 2;
      margin: 30px 0;
    }
    .app-txt + .app-txt {
      margin-top: 10px;
    }
  }
  &__role{
    display: block;
    font-size: 20px;
    font-weight: normal;
    line-height: 1.25;
    color: var(--col-black);
    margin-bottom: 40px;
  }
  &__carousel{
    width: 380px;
    padding: 0 30px;
    margin: auto;
    @media (max-width: $breakpoint-md){
      width: auto;
      max-width: 500px;
    }
    @media (max-width: $breakpoint-sm){
      padding: 0 15px;
    }
    .flickity-prev-next-button{
      color: var(--col-d4);
      @media (max-width: $breakpoint-sm){
       font-size: 30px;
      }
    }
    .flickity-prev-next-button.previous{
      left: -10px;
      @media (max-width: $breakpoint-sm){
        left: -15px;
      }
    }
    .flickity-prev-next-button.next{
      right: 0;
      @media (max-width: $breakpoint-sm){
        right: -15px;
      }
    }
  }
  &__{}
}


.instructor-profile{
  background: center no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  @media (max-width: $breakpoint-lg){
    text-align: center;
    .btn{
      margin: auto;
    }
  }
  &__column{
    margin-bottom: 30px;
  }
  &__video{
    max-width: 570px;
    width: 100%;
    height: 315px;
    margin: auto;
    @media (max-width: $breakpoint-sm){
      height:220px;
      margin-bottom:25px;
    }
  }
  &__quote{
    margin-bottom: 30px;
    span{
      display: block;
      color: #fff;
      text-transform: uppercase;
      font-size: 20px;
      font-weight: bold;
      line-height: normal;
    }
    p{
      font-size: 16px;
      font-weight: normal;
      line-height: 1.56;
      color: #d4d4d4;
    }
  }

}


.train-academy-head{
  &__row {
    @media (max-width: 1200px){
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
  }
  &__content{
    padding: 30px 0;
    height: 535px;
    @media (max-width: 1200px){
      height: inherit !important;
    }
  }
  &__img{
    position: absolute;
    right: 15px;
    height: 535px;
    @media (max-width: 1200px){
      position: inherit;
      height: inherit !important;
      right: inherit;
      max-width: 100%;
    }
    @media (max-width: $breakpoint-lg){
      margin-top: 30px;
    }

    @media (max-width: 1400px) and (min-width: 1198px) {
      width: 95%;
      height: auto !important;
    }
  }
  &__{}
}


.train-academy-data{
  padding: 60px 0 30px 0;
  background-color: var(--col-light-gray);
  &__column{
    margin-bottom: 30px;
  }
  &__txt{
    position: relative;
    padding-left: 55px;
    display: block;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    color: var(--col-grey);
    span, a {
      display: block;
    }
  }
  &__icon{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    display: inline-block;
    font-size: 32px;
    color: var(--col-black);
    margin-right: 5px;
  }
}


.train-academy-info{
  .text-block + .text-block{
    margin-top: 50px;
  }
  &__table{
    margin-bottom: 50px;
    .shop-table__column:nth-child(1){
      @include grid-size(33.33333%);
    }
    .shop-table__column:nth-child(2){
      @include grid-size(66.66666%);
    }
  }
}

#train-map{
  height: 400px;
}