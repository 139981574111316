@import "../tools/mixins";
@import "../tools/breakpoints";


.footer{
  position: relative;
  padding: 55px 0 30px;
  background: url("../img/background/footer-bg.png") center no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  &__overlay{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.75;
    background-color: var(--col-black);
  }
  &__content{
    position: relative;
    z-index: 3;
  }
  &__column{
    margin-bottom: 25px;
    @media (max-width: $breakpoint-sm){
      &:nth-last-of-type{
        margin-bottom: 0;
      }
    }
  }

  &__title{
    display: block;
    font-family: 'Myriad Pro', sans-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.39;
    color: #fff;
    padding-bottom: 5px;
    margin-bottom: 15px;
    border-bottom: 1px solid  rgba(160, 160, 160, 0.6);
  }
  &__links{
    li{}
    a{
      font-family: 'Myriad Pro', sans-serif;
      font-size: 14px;
      font-weight: normal;
      line-height: 1.79;
      color: #ffffff;
      transition: all 0.2s ease;
      &:hover{
        color: var(--col-primary);
      }
    }
  }

  &__logo{
    @media (max-width: $breakpoint-md){
      margin: auto auto 30px;
    }
  }
}


.footer-form{
  position: relative;
  &__input{
    border: 0;
    color: #efefef;
    padding-right: 80px;
    border-radius: 3px;
    background-color: rgba(216, 216, 216,0.15);
  }
  &__btn{
    position: absolute;
    right: 0;
    top: 0;
    width: 70px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    background-color: var(--col-grey);
  }
}

.footer-social{
  &__item{
    display: inline-block;
    & + &{
      margin-left: 25px;
    }
  }
  &__link{
    display: block;
    font-size: 40px;
    color: #fff;
    @include transition;
    &:hover{
      color: var(--col-primary);
    }
  }
}


.copyright{
  padding: 30px 0;
  background-color: var(--col-black);

  @media (max-width: $breakpoint-sm){
    .column{
      @include grid-size(100%);
      text-align: center;
    }
  }
  &__txt{
    display: block;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.79;
    color: #a0a0a0;
  }
  &__link{
    color: #fff;
    @include transition;
    &:hover{
      color: var(--col-primary);
    }
  }
}