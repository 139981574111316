@import "../tools/mixins";
@import "../tools/breakpoints";

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
}

a:hover {
  text-decoration: none !important;
}

.btn.btn-primary {
  background-color: none !important;
  box-shadow: none !important;
}

.btn-primary:active {
  background: none !important;
}

strong {
  font-weight: bold;
}

.page {
  // position: relative;
  overflow-x: hidden;
}

// text classes
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}

.block-center {
  margin: 0 auto;
}

.title-block {
  // position: relative;
  margin-bottom: 25px;
  &.--modifier {
    margin-bottom: 50px;
  }
  &.--white {
    &.skill-center-title-mob {
      @media (min-width: $breakpoint-md) {
        display: none;
      }
    }

    &.skill-center-title-desktop {
      @media (max-width: $breakpoint-sm) {
        display: none;
      }
    }

    .title-block__subtitle {
      color: #d4d4d4;
    }

    .title-block__title {
      color: #fff;
    }
  }
  &__subtitle {
    display: block;
    opacity: 0.75;
    font-family: "Myriad Pro", sans-serif;
    font-size: 20px;
    font-weight: normal;
    line-height: normal;
    color: var(--col-grey);
    margin-bottom: 10px;
    @media (max-width: $breakpoint-sm) {
      margin-bottom: 0;
      font-size: 18px;
    }
    &.--white {
      color: #fff !important;
    }

    &.bronko-academy-home {
      color: #fff;
    }

    // overriding default color
    &.testimonial {
      & span {
        color: var(--col-grey) !important;
      }
    }
  }
  &__title {
    display: block;
    font-family: "Myriad Pro", sans-serif;
    font-size: 60px;
    text-transform: uppercase;
    font-weight: normal;
    line-height: normal;
    color: var(--col-black);
    @media (max-width: $breakpoint-md) {
      font-size: 45px;
    }
    @media (max-width: $breakpoint-sm) {
      font-size: 36px;
    }
    span {
      font-weight: bold;
    }

    &.product_page {
      font-size: 45px;
    }

    &.--white {
      color: #fff;
    }
  }
}

.text-block {
  margin-bottom: 25px;
  &.--modifier {
    margin-bottom: 0;
  }
  .app-txt + .app-txt {
    margin-top: 10px;
  }

  &.video-share-block {
    @media (max-width: $breakpoint-md) {
      text-align: left !important;
      margin-bottom: 0px;
    }
  }

  &.tab-block {
    padding-top: 40px;
  }

  .tab-subheading {
    font-weight: 600;
    color: var(--col-black);
  }
}

.app-title-sm {
  display: block;
  font-family: "Myriad Pro", sans-serif;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bold;
  line-height: normal;
  color: var(--col-black);
  margin-bottom: 20px;
  &.--modifier {
    margin-bottom: 0;
  }
}

.app-txt {
  display: block;
  font-family: "Myriad Pro", sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.31;
  color: var(--col-grey);
  &.--white {
    color: #fff;
  }
  &.--black {
    color: var(--col-black);
  }
  &.--small {
    font-size: 14px;
  }
}

.app-link {
  font-family: "Myriad Pro", sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.31;
  color: var(--col-primary);
}

.app-list {
  padding-left: 17px;
  list-style-type: disc;
  &.--modifier {
    li {
      padding-left: 5px;
      margin-bottom: 5px;
    }
  }
  li {
    display: list-item;
    padding-left: 10px;
    margin-bottom: 10px;
    &:nth-last-of-type(1) {
      margin-bottom: 0;
    }
  }
  &__item {
    display: list-item;
    padding-left: 10px;
    margin-bottom: 10px;
    &:nth-last-of-type(1) {
      margin-bottom: 0;
    }
  }
}

.quote {
  margin-bottom: 20px;
  &.--white {
    .quote__title {
      color: #fff;
    }
    .quote__txt {
      color: #d4d4d4;
    }
  }
  &__title {
    display: block;
    font-size: 20px;
    font-weight: bold;
    line-height: normal;
    color: var(--col-grey);
  }
  &__txt {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.56;
    color: var(--col-grey);
  }
}

// end of text classes

.image {
  display: block;
  max-width: 100%;

  &.search-images {
    height: 100px;
    /* width: 150px; */
    margin-left: 20px;
    margin-top: 10px;
  }
}

.section {
  position: relative;
  padding: 60px 0 80px;

  &.player-form {
    padding-top: 190px;

    @media (max-width: $breakpoint-lg) {
      padding-top: 130px;
    }
  }

  &.mob {
    @media (max-width: $breakpoint-md) {
      padding-top: 20px !important;
    }
  }
  &.--padding {
    padding: 60px 0 50px;
  }
  &.--background {
    background: center no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
  }
  &.--grey-background {
    background-color: var(--col-light-gray);
  }
  &.--ice-bg {
    background: url(../img/ice-bg.png) center no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
  }

  &.video-section {
    padding-top: 20px;
    @media (max-width: $breakpoint-md) {
      padding: 0px;
    }
  }

  &.retail {
    padding: 0 0 80px;

    @media (max-width: $breakpoint-md) {
      padding-top: 20px;

      & .title-block {
        margin-bottom: 10px;
      }
    }
  }

  &.bronko-academy-wrapper {
    background: var(--col-black);
    @media (max-width: $breakpoint-sm) {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
}

.hidden {
  visibility: hidden;
  display: none;
}

.m-auto {
  display: block;
  margin: auto;
}
.text-right {
  text-align: right;
}
.d-inline {
  display: inline-block !important;
}

.mb-0 {
  margin-bottom: 0;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-45 {
  margin-bottom: 45px;
}
.mb-75 {
  margin-bottom: 75px;
}

.mt-0 {
  margin-top: 0;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-45 {
  margin-top: 45px;
}
.mt-75 {
  margin-top: 75px;
}

.display-table {
  display: table;
  height: 100%;
  width: 100%;
  &__cell {
    width: 100%;
    display: table-cell;
    vertical-align: middle;

    &.slideshow-cell {
      display: table;
      //  vertical-align: none;
    }
  }
}

.tab-content {
  display: none;
  animation: fadeEffect 0.5s;
  -webkit-animation: fadeEffect 0.5s;
  &.active {
    display: block;
  }
}

.js-panel-head {
  cursor: pointer;
}
.js-panel-content {
  display: none;
}
body.cke_show_borders .js-panel-content {
  display:block;
}

.js-panel {
  &.js-active {
    .js-panel-content {
      //display: block;
    }
  }
}
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
}

.video-mob {
  @media (min-width: $breakpoint-md) {
    display: none;
  }

  // @media (max-width: $breakpoint-md) {
  //   display: block;
  // }
}

.video-desktop {
  @media (max-width: $breakpoint-md) {
    display: none;
  }

  // @media (min-width: $breakpoint-md) {
  //   display: block;
  // }
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.alert {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.video-feedback-desktop {
  @media (max-width: $breakpoint-md) {
    display: none;
  }
}

.video-feedback-mob {
  @media (min-width: $breakpoint-md) {
    display: none;
  }

  & .instructor-profile__column {
    padding: 0;
  }
}

.instructor-profile__video-mob {
  @media (max-width: $breakpoint-md) {
    display: block;
  }
  display: none;
}

.instructor-profile__video {
  @media (max-width: $breakpoint-md) {
    display: none;
  }
}

// search page style

.limit {
  @media (max-width: $breakpoint-sm) {
    margin-left: 8px;
  }
}

input.form-control.col-lg-3.filter-input {
  margin-left: 10px;
  margin-right: 10px;
}

input#button-search {
  margin-left: 15px;
  @media (max-width: $breakpoint-sm) {
    margin-left: 0px;
  }
}

.search-criteria-title {
  padding-top: 10px;
  margin-left: 10px;
}

// playlist video overlay unlock

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  /* -webkit-transform: translate(-50%, -50%); */
  /* transform: translate(-50%, -50%); */
  color: white;
  /* display: flex; */
}

.overlay-text {
  top: 180px;

  &.mob {
    top: 45px;
    font-size: 15px;
  }
}

// account page instructor profile

.profile-title-mob {
  display: none;
  @media (max-width: $breakpoint-md) {
    display: block;
  }
}

.profile-title-desktop {
  @media (max-width: $breakpoint-md) {
    display: none;
  }
}

#alert-success-box { display: none; }

// Category steps
.camps-mob { display: none; }

@media (max-width: $breakpoint-md) {
  .camps-mob { display: block; }
  .title-block-camps { display: none; }
}

//video section
.v-section iframe {
  width:100%;
}