@import "../tools/mixins";
@import "../tools/breakpoints";

.dashboard{
  &__title-block{
    margin-bottom: 40px;
    // cursor:pointer;
  }
  &__title{
    display: block;
    font-family: 'Myriad Pro', sans-serif;
    font-size: 60px;
    text-transform: uppercase;
    font-weight: normal;
    line-height: normal;
    color: var(--col-black);
    margin-bottom: 20px;
    @media (max-width: $breakpoint-md){
      font-size: 45px;
    }
    @media (max-width: $breakpoint-sm){
      font-size: 36px;
    }
    span{
      font-weight: bold;
    }

    &.account-accordian {
      cursor: pointer;
      font-size: 35px;
      @media (max-width: $breakpoint-sm) {
        font-size: 35px;
      }
    }
  }
  &__subtitle{
    display: block;
    font-family: 'Myriad Pro', sans-serif;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.25;
    color: var(--col-grey);
  }

  &__toggle{
    display: none;

    @media (max-width: $breakpoint-lg){
      // display: block;
    }
  }
  &__toggle-btn{
    position: relative;
    padding-right: 30px;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    color: var(--col-primary);
    margin-bottom: 30px;
    &:after{
      content: "\e915";
      font-family: 'icomoon' !important;
      position: absolute;
      top: 2px;
      right: 10px;
      font-size: 14px;
      transform: scale(-1) rotate(-90deg);
      color: var(--col-primary);
      transition: all 0.25s ease;
    }
    &.js-active{
      &:after{
        transform: scale(-1) rotate(90deg);
      }
    }
  }

}

.registration{
  &__wrap{
    height: 490px;
    padding: 20px 30px 20px 20px;
    overflow-y: scroll;
    border-radius: 3px;
    border: solid 1px #d4d4d4;
    margin-bottom: 40px;
    text-align: left;
  }
}

.dashboard-head{
  position: relative;
  margin-top: 135px;
  height: 275px;
  background: center no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  @media (max-width: $breakpoint-lg){
    height: 200px;
    margin-top: 100px;
  }
  @media (max-width: 655px){
    margin-top: 90px;
  }
  &__overlay{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.3);
  }
  &__content{
    position: relative;
    z-index: 3;
  }
  &__title{
    display: block;
    font-family: 'Myriad Pro', sans-serif;
    font-size: 60px;
    text-transform: uppercase;
    font-weight: bold;
    line-height: normal;
    color: #fff;
    @media (max-width: $breakpoint-md){
      font-size: 45px;
    }
    @media (max-width: $breakpoint-sm){
      font-size: 36px;
    }
  }
}

.dashboard-user{
  margin-bottom: 30px;
  max-width: 270px;
  //@media (max-width: $breakpoint-lg){
  //  display: none;
  //}
  &__content{
    padding: 15px;
    background-color: #fff;
    border: solid 1px #d4d4d4;
  }
  &__image{
    height: 240px;
    background: center no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    margin-bottom: 10px;
    @media (max-width: $breakpoint-sm){
      @include grid-size(100%);
    }
  }
  &__name{
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
    line-height: normal;
    color: var(--col-black);
    margin-bottom: 10px;
    @media (max-width: $breakpoint-sm){
      @include grid-size(100%);
    }
  }
  &__list{
    @media (max-width: $breakpoint-sm){
      @include grid-size(100%);
    }
    &-item{
      display: block;
      &:last-of-type{
        .dashboard-user__list-link{
          border-bottom: 0;
        }
      }
    }
    &-link{
      display: block;
      padding: 14px 0;
      text-align: center;
      font-size: 18px;
      font-weight: normal;
      line-height: normal;
      color: var(--col-grey);
      border-bottom: 1px solid #d8d8d8;
      @include transition;
      &:hover{
        color: #fff;
        background-color: var(--col-black);
      }
      &.active{
        color: #fff;
        background-color: var(--col-black);
      }
    }

  }
}


.dashboard-js-toggle{
  @media (max-width: $breakpoint-lg){
    display: none;
  }
  @media (min-width: 992px){
    display: block!important;
  }
}

.dashboard-sidebar{
  position: relative;
  padding: 20px 15px;
  border: solid 1px #d4d4d4;
  &__item{
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #d4d4d4;
    &:last-child{
      margin-bottom: 0;
      border-bottom: 0;
      padding-bottom: 0;
    }

  }
  &__title{
    display: block;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: normal;
    color: var(--col-black);
  }
  &__link{
    display: block;
    font-size: 14px;
    font-weight: normal;
    line-height: normal;
    color: var(--col-black);
    margin-bottom: 20px;
  }
}

.sidebar-categories{
  &__item{
    position: relative;
    margin-bottom: 15px;
    display: block;
    padding-right: 30px;
    &:last-child{
      margin-bottom: 0;
    }
    &:hover{
      .sidebar-categories__name, .sidebar-categories__count{
        color: var(--col-primary);
      }
    }
  }
  &__name, &__count{
    display: block;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    color: var(--col-grey);
    @include transition;
  }
  &__count{
    font-weight: normal;
  }
  &__icon{
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    color: var(--col-primary);
    font-size: 20px;
    cursor: pointer;
    text-align: center;
    border: 1px solid var(--col-primary);
    border-radius: 50%;
    &.fas {
      width:20px;
      height:20px;
      font-size:10px;
      display:flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.sidebar-playlist{
  &__item{
    position: relative;
    margin-bottom: 15px;
    &:hover{
      .sidebar-playlist__name{
        color: var(--col-primary);
      }
      .sidebar-playlist__count{
        color: var(--col-primary);
      }
    }
    &:last-child{
      margin-bottom: 0;
    }
    &.locked{
      &:after{
        content: "\e91c";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        font-family: 'icomoon' !important;
        color: var(--col-primary);
        font-size: 16px;
      }
      .sidebar-playlist__column:nth-child(2){
         padding-right: 30px;
      }
    }
  }
  &__row{
    margin: 0 -5px;
  }
  &__column{
    padding: 0 5px;
    &:nth-child(1){
      @include grid-size(100px);
    }
    &:nth-child(2){
      @include grid-size(calc(100% - 100px));
    }
  }
  &__name{
    display: block;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    color: var(--col-grey);
    @include transition;
  }
  &__count, &__time{
    display: block;
    font-size: 14px;
    font-weight: normal;
    line-height: normal;
    color: #a0a0a0;
    @include transition;
  }


}

.sidebar-keywords{
  position: relative;
  padding-bottom: 20px;
  &.js-active{
    .sidebar-keywords__content{
      max-height: 99999px;
      @include transition;
    }
    .sidebar-keywords__btn{
      transform: translateX(-50%) scale(1);
    }
  }
  &__content{
    max-height: 190px;
    overflow: hidden;
    @include transition;
  }
  .form__group-15:last-child{
    margin-bottom: 0;
  }
  &__btn{
    position: absolute;
    left: 50%;
    bottom: -5px;
    transform: translateX(-50%) scale(-1);
    width: 30px;
    height: 15px;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    color: var(--col-black);
    @include transition;
  }
}

.dashboard-search, .dashboard-comment{
  margin-bottom: 40px;
  &__icon{
    position: absolute;
    left: 8px;
    top: 12px;
    color: var(--col-grey);
  }
  &__input{
    padding: 0 175px 0 30px;
  }
  &__btn{
    position: absolute;
    right: 0;
    top: 0;
    width: 170px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
  @media (max-width: $breakpoint-md){
    &__input{
      padding: 0 50px 0 10px;
    }
    &__btn {
      width:auto;
      padding:0 15px;
    }
  }

  &.share-btn {
    @media(max-width: $breakpoint-sm) {
      display: none;
    }
  }

  @media (max-width: $breakpoint-sm) {
    margin-bottom: 30px;
    margin-top: 20px;
  }
}

.bronko-content{
  &__block{
    border-bottom: 1px solid #d8d8d8;
    padding-bottom: 40px;
    margin-bottom: 40px;
    &.js-active{
      .bronko-content__icon{
        &:after{
          top: 100%;
        }
      }
      .bronko-content__body{
        display: block;
      }
    }
  }
  &__head{
    position: relative;
    padding-right: 30px;
    .icon-play-circle{
      position: relative;
      top: 2px;
      margin-left: 10px;
      font-size: 20px;
      color: var(--col-primary);
      border: 1px solid var(--col-primary);
      border-radius: 50%;
    }
    .icon-shop_cart{
      position: relative;
      top: 3px;
      margin-left: 5px;
      font-size: 24px;
      color: var(--col-primary);
    }
  }
  &__body{
    padding-top: 35px;
  }
  &__title{
    display: inline-block;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
    line-height: normal;
    color: var(--col-black);
    @include transition;
    &-shop{
      display: inline-block;
      width: 30px;
      height: 25px;
      vertical-align: sub;
      background: url(../img/svg/shop_cart_red.svg) center no-repeat;
      background-size: contain;
    }
    &:hover{
      color: var(--col-primary);
    }
  }
  &__icon{
    position: absolute;
    right: 0;
    top: 6px;
    overflow: hidden;
    width: 16px;
    height: 16px;
    &:before,&:after{
      content: '';
      position: absolute;
      background-color: var(--col-primary);
      @include transition;
    }
    &:before{
      height: 2px;
      left: 0;
      right: 0;
      top: 7px;
    }
    &:after{
      height: 100%;
      width: 2px;
      bottom: 0;
      top: 0;
      left: 7px;
    }
  }
  &__subicon{
    position: relative;
    top: 2px;
    margin-left: 10px;
    font-size: 20px;
    color: var(--col-primary);
    border: 1px solid var(--col-primary);
    border-radius: 50%;
  }
}

.personal-info{
  margin-bottom: 60px;
  &__column-1{
    @include grid-size(200px);
    @media (max-width: $breakpoint-md){
      @include grid-size(100%);
    }
  }
  &__column-2{
    @include grid-size(calc(100% - 200px));
    @media (max-width: $breakpoint-md){
      @include grid-size(100%);
    }
  }
  &__img-wrap{
    max-width: 240px;
    @media (max-width: $breakpoint-md){
      margin-bottom: 30px;
    }
  }
  &__upload-form{
    margin: 15px 0;
  }
  &__upload{
    position: relative;
    text-align: center;
    padding: 0 30px;
    label{
      cursor: pointer;
      color: var(--col-primary);
    }
  }
  &__remove{
    position: absolute;
    right: 0;
    top: 0;
    @include transition;
    &:hover{
      color: var(--col-primary);
    }
  }
}

.players-table{
  padding: 10px 15px;
  border: 1px solid #d4d4d4;
  margin-bottom: 40px;
  &__row{
    position: relative;
    padding: 10px 0;
    border-bottom: 1px solid #d4d4d4;
    &:nth-last-child(1){
      border-bottom: 0;
    }
  }
  &__name{
    position: relative;
    padding-left: 40px;
    padding-right: 70px;
  }
  &__remove{
    position: absolute;
    left: 15px;
    top: 11px;
    cursor: pointer;
    z-index: 9;
    font-size: 15px;
    color: var(--col-primary);
  }
  &__btn{
    position: absolute;
    right: 15px;
    top: 12px;
  }
  &__txt{
    font-size: 18px;
    font-weight: 600;
    line-height: 1.39;
    color: var(--col-primary);
  }
}

.history-table{
  padding: 15px 30px;
  border: solid 1px #d4d4d4;
  margin-bottom: 60px;
  &__row{
    &.--head{
      margin-bottom: 20px;
      @media (max-width: $breakpoint-lg){
        display: none;
      }
      span{
        font-weight: 600;
        color: var(--col-black);
      }
    }
    &.--body{
      position: relative;
      border-bottom: solid 1px #d4d4d4;
      padding-bottom: 15px;
      &:last-child{
        border-bottom: 0;
        padding-bottom: 0;
      }
    }
    &.--body + &.--body{
      margin-top: 10px;
    }
    span{
      display: block;
      font-size: 16px;
      font-weight: normal;
      line-height: 1.25;
      text-align: left;
      color: var(--col-grey);
    }

    .column{
      &:nth-child(1){
        @include grid-size(16.66667%);
      }
      &:nth-child(2){
        @include grid-size(16.66667%);
      }
      &:nth-child(3){
        @include grid-size(24.99999%);
      }
      &:nth-child(4){
        @include grid-size(8.33333%);
      }
      &:nth-child(5){
        @include grid-size(16.66667%);
      }
      &:nth-child(6){
        @include grid-size(8.33333%);
      }
      &:nth-child(7){
        @include grid-size(8.33333%);
      }
      @media (max-width: $breakpoint-lg){
        @include grid-size(100%!important);
        padding-left: 130px;
        position: relative;
        margin-bottom: 5px;
        &:before{
          content: attr(data-head) " ";
          position: absolute;
          left: 15px;
          font-size: 16px;
          font-weight: 600;
          color: var(--col-black);
        }

        @media (max-width: $breakpoint-sm) {
          &:nth-child(6){
            padding-left: 12px;
          }
          &:nth-child(7){
            padding-left: 12px;
           
            .players-table__btn {
              right: 0;
              left: 12px;

              &.btn-link {
                &:after {
                  left: 45px;
                }
              }
            }
          }
        }

      }
    }

    // .column{
    //   &:nth-child(1){
    //     @include grid-size(22.22222%);
    //   }
    //   &:nth-child(2){
    //     @include grid-size(11.11111%);
    //   }
    //   &:nth-child(3){
    //     @include grid-size(33.33333%);
    //   }
    //   &:nth-child(4){
    //     @include grid-size(11.11111%);
    //   }
    //   &:nth-child(5){
    //     @include grid-size(22.22222%);
    //   }
    //   @media (max-width: $breakpoint-lg){
    //     @include grid-size(100%!important);
    //     padding-left: 130px;
    //     position: relative;
    //     margin-bottom: 5px;
    //     &:before{
    //       content: attr(data-head) ": ";
    //       position: absolute;
    //       left: 15px;
    //       font-size: 16px;
    //       font-weight: 600;
    //       color: var(--col-black);
    //     }
    //   }
    // }
  }
}

.subscription-table{
  padding: 15px 30px;
  border: solid 1px #d4d4d4;
  &__row{
    &.--head{
      margin-bottom: 20px;
      @media (max-width: $breakpoint-lg){
        display: none;
      }
      span{
        font-weight: 600;
        color: var(--col-black);
      }
    }
    &.--body{
      position: relative;
      border-bottom: solid 1px #d4d4d4;
      padding-bottom: 15px;
      &:last-child{
        border-bottom: 0;
        padding-bottom: 0;
      }
    }
    &.--body + &.--body{
      margin-top: 15px;
    }
    span{
      display: block;
      font-size: 16px;
      font-weight: normal;
      line-height: 1.25;
      text-align: left;
      color: var(--col-grey);
    }
    .column{
      &:nth-child(1){
        @include grid-size(20%);
      }
      &:nth-child(2){
        @include grid-size(24.44444%);
      }
      &:nth-child(3){
        @include grid-size(22.22222%);
      }
      &:nth-child(4){
        @include grid-size(19.1111%);
      }
      &:nth-child(5){
        @include grid-size(14.2222%);
      }
      @media (max-width: $breakpoint-lg){
        @include grid-size(100%!important);
        padding-left: 160px;
        position: relative;
        margin-bottom: 7px;
        &:before{
          content: attr(data-head) ": ";
          position: absolute;
          left: 15px;
          font-size: 16px;
          font-weight: 600;
          color: var(--col-black);
        }
      }
    }
  }

  &__status{
    &.active{
      color: #45cf53;
    }
    &.expired{
      color: var(--col-primary);
    }
  }
}

.address-table{
  &__content{
    padding: 10px 15px;
    border: 1px solid #d4d4d4;
    margin-bottom: 40px;
  }
  &__bottom{
    margin-bottom: 80px;
  }
  &__row{
    position: relative;
    padding: 10px 0;
    border-bottom: 1px solid #d4d4d4;
    &:nth-last-child(1){
      border-bottom: 0;
    }
  }
  &__name{
    position: relative;
    padding-left: 40px;
    padding-right: 70px;
    span{
      display: block;
    }
  }
  &__remove{
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 9;
    font-size: 15px;
    color: var(--col-primary);
  }
  &__btn{
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
  &__txt{
    font-size: 18px;
    font-weight: 600;
    line-height: 1.39;
    color: var(--col-primary);
  }
}

.credits-table{
  padding: 15px 30px;
  border: solid 1px #d4d4d4;
  margin-bottom: 60px;
  margin-top: 20px;
  &__row{
    &.--head{
      margin-bottom: 20px;
      @media (max-width: $breakpoint-lg){
        display: none;
      }
      span{
        font-weight: 600;
        color: var(--col-black);
      }
    }
    &.--body{
      position: relative;
      border-bottom: solid 1px #d4d4d4;
      padding-bottom: 15px;
      &:last-child{
        border-bottom: 0;
        padding-bottom: 0;
      }
    }
    &.--body + &.--body{
      margin-top: 10px;
    }
    span{
      display: block;
      font-size: 16px;
      font-weight: normal;
      line-height: 1.25;
      text-align: left;
      color: var(--col-grey);
    }
    .column{
      &:nth-child(1){
        @include grid-size(22.22222%);
      }
      &:nth-child(2){
        @include grid-size(55.55555%);
      }
      &:nth-child(3){
        @include grid-size(22.22222%);
      }
      @media (max-width: $breakpoint-lg){
        @include grid-size(100%!important);
        padding-left: 160px;
        position: relative;
        margin-bottom: 5px;
        &:before{
          content: attr(data-head) ": ";
          position: absolute;
          left: 15px;
          font-size: 16px;
          font-weight: 600;
          color: var(--col-black);
        }
      }
    }
  }
}

.manage-list{
  counter-reset: items;
  margin-bottom: 50px;
  &__item{
    position: relative;
    cursor: move;
    padding: 20px 0;
    background-color: #fff;
    border-bottom: 1px solid var(--col-d4);
    &:last-child{
      border-bottom: 0;
    }
    &:before {
      counter-increment: items;
      content: "" counter(items) "";
      position: absolute;
      left: 30px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 20px;
      font-weight: bold;
      line-height: normal;
      color: var(--col-black);
      @media (max-width: $breakpoint-md){
        left: 0;
      }
    }
  }
  &__row{
    padding: 0 45px 0 100px;
    @media (max-width: $breakpoint-md){
      padding: 0 30px 0 15px;
    }
    .column:nth-child(1){
      @include grid-size(200px);
      @media (max-width: $breakpoint-sm){
        @include grid-size(100%);
      }
    }
    .column:nth-child(2){
      @include grid-size(calc(100% - 200px));
      @media (max-width: $breakpoint-sm){
        @include grid-size(100%);
      }
    }
  }
  &__title{
    display: block;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.25;
    color: var(--col-grey);
    margin-bottom: 10px;
  }
  &__author, &__count, &__status{
    display: block;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.25;
    color: #a0a0a0;
  }
  &__icon{
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    color: var(--col-primary);
    font-size: 20px;
    cursor: pointer;
    text-align: center;
    border: 1px solid var(--col-primary);
    border-radius: 50%;
  }
}

.content-available{
  position: relative;
  height: 560px;
  background: left top no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  margin-bottom: 50px;
  @media (max-width: $breakpoint-md){
    height: 400px;
  }
  &__overlay{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6));
  }
  &__content{
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    transform: translateY(-50%);
    @media (max-width: $breakpoint-md){
      padding: 15px;
    }
  }
  &__txt{
    font-size: 20px;
    font-weight: normal;
    line-height: 1.15;
    color: #fff;
    margin-bottom: 25px;
    @media (max-width: $breakpoint-md){
      font-size: 18px;
    }
    span{
      display: block;
    }
  }
  &__title{
    display: block;
    font-size: 36px;
    font-weight: bold;
    line-height: normal;
    color: #fff;
    margin-bottom: 50px;
    @media (max-width: $breakpoint-md){
      font-size: 28px;
    }
  }
  &__{}
}

.playlist-drop{
  position: relative;
  &.is-active{
    .playlist-drop__content{
      display: block;
    }
  }
  &__content{
    display: none;
    position: absolute;
    width: 100%;
    right: 15px;
    top: 30px;
    z-index:2;
    background-color: #fff;
    border: 1px solid var(--col-d4);
    @media (max-width: $breakpoint-lg){
      left: 0;
      width: 100%;
    }
    @media (max-width: $breakpoint-md) {
      left: 0;
     // margin-left: 15px;
    }

  }
  &__content-top{
    padding: 15px;
    border-bottom: 1px solid var(--col-d4);
    text-align: left;
    .form__group-15:last-child {
      margin-bottom: 0;
    }
    .form__group-15:last-child > .checkbox {
      margin-bottom: 0;
    }
  }
  &__content-bottom {
    padding: 15px;
    margin-left: 10px;

    & .playlist-title {
      margin-right: 10px;
      padding-top: 5px;

      // & .form-control {

        &.playlist-form:focus  {
          // height: 30px!important;

          color: #495057;
          background-color: #fff;
          border-color: none!important;
          outline: 0;
          box-shadow: none!important;

        }

       
      // }
    }
  }
  &__add{
    position: relative;
    display: inline-block;
    padding-left: 20px;
    font-size: 16px;
    font-weight: normal;
    line-height: normal;
    color: var(--col-grey);
    &:before{
      content: "\e91e";
      position: absolute;
      top: -4px;
      left: -4px;
      font-size: 26px;
      font-family: 'icomoon' !important;
      color: var(--col-grey);
      transition: all 0.25s ease;
    }
  }
  &__{}

  &.playlist-align {
    text-align: right;
    @media (max-width: $breakpoint-md) {
      text-align: left;
    }
  }
}




.play-list-video{
  width: 100%;
  height: 555px;
  margin-bottom: 30px;
  @media (max-width: $breakpoint-lg){
    height: 500px;
  }
  @media (max-width: $breakpoint-md){
    height: 400px;
  }
  @media (max-width: $breakpoint-sm){
    height: initial;
  }
}

.play-list{
  background-color: #3e3e3e;
  margin-bottom: 30px;
  &.js-active{
    .play-list__toggle-icon{
      &:after{
        top: 100%;
      }
    }
    .play-list__bottom{
      display: block;
    }
  }
  &__top{
    position: relative;
    padding: 15px 90px 15px 15px;
    border-bottom: 1px solid #555;
  }
  &__bottom{
    counter-reset: play-item;
  }
  &__title, &__subtitle{
    display: block;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.25;
    color: #fff;
  }
  &__title{
    font-weight: 600;
  }

  // &__icon{
  //   position: absolute;
  //   left: 0;
  //   top: 12px;
  //   font-size: 30px;
  //   color: var(--col-black);
  //   @media (max-width: $breakpoint-md){
  //     top: 3px;
  //   }
  //   @media (max-width: $breakpoint-sm){
  //     font-size: 24px;
  //     top: 6px;
  //   }
  // }

  // &__toggle-icon{
  //   position: absolute;
  //   right: 15px;
  //   top: 50%;
  //   cursor: pointer;
  //   overflow: hidden;
  //   width: 16px;
  //   height: 16px;
  //   &:before,&:after{
  //     content: '';
  //     position: absolute;
  //     background-color: var(--col-primary);
  //     @include transition;
  //   }
  //   &:before{
  //     height: 2px;
  //     left: 0;
  //     right: 0;
  //     top: 7px;
  //   }
  //   &:after{
  //     height: 100%;
  //     width: 2px;
  //     bottom: 0;
  //     top: 0;
  //     left: 7px;
  //   }

  //   @media (max-width: $breakpoint-md) {
  //     top: 12px;
  //   }
  // }

  &__toggle-icon{
    position: absolute;
    right: 15px;
    top: 50%;
    cursor: pointer;
    transform: translateY(-50%);
    overflow: hidden;
    width: 16px;
    height: 16px;
    &:before,&:after{
      content: '';
      position: absolute;
      background-color: var(--col-d4);
      @include transition;
    }
    &:before{
      height: 2px;
      left: 0;
      right: 0;
      top: 6px;
      background-color: var(--col-d4);
      transition: all 0.25s ease-out;
    }
    &:after{
      height: 100%;
      width: 2px;
      bottom: 0;
      top: 0;
      left: 7px;
    }

  }
  &__{}
  &__{}
}

.play-item{
  position: relative;
  padding: 20px 0;
  @include transition;
  &.active{
    background-color: #555;
  }
  &:hover{
    background-color: #555;
  }
  &:before {
    counter-increment: play-item;
    content: "" counter(play-item) "";
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    font-weight: bold;
    line-height: normal;
    color: #d4d4d4;
    @media (max-width: $breakpoint-md){
      left: 15px;
    }
  }
  &__row{
    padding: 0 45px 0 100px;
    @media (max-width: $breakpoint-md){
      padding: 0 30px 0 30px;
    }
    .column:nth-child(1){
      @include grid-size(200px);
      @media (max-width: $breakpoint-sm){
        @include grid-size(50%);
      }
    }
    .column:nth-child(2){
      @include grid-size(calc(100% - 200px));
      @media (max-width: $breakpoint-sm){
        @include grid-size(50%);
      }
    }
  }

  &__title,&__time{
    display: block;
    font-size: 16px;
    font-weight: normal;
    line-height: normal;
    color: #fff;
  }
  &__title{
    font-weight: 600;
    margin-bottom: 5px;
    @media (max-width: $breakpoint-sm){
      margin-top: 5px;
    }
  }
}

.autoplay{
  position: absolute;
  right: 45px;
  top: 50%;
  cursor: pointer;
  transform: translateY(-50%);
  &__toggle{
    cursor: pointer;
  }

  input:checked + &__track {
    background-color: green;
    .autoplay__handle{
      transform: translate3d(20px, 0, 0);
    }
  }
  &__track{
    display: inline-block;
    pointer-events: none;
    width: 40px;
    height: 20px;
    border-radius: 20px;
    cursor: pointer;
    background-color: #fff;
    transition-duration: 0.3s;
    transition-property: background-color, border;
    transition-timing-function: ease-in-out;
  }

  &__handle{
    position: absolute;
    border-radius: 27px;
    display: block;
    width: 16px;
    height: 16px;
    left: 1px;
    top: 2px;
    cursor: pointer;
    background-color: #fff;
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.35), 0 1px 1px rgba(0, 0, 0, 0.15);
    transition: 0.3s cubic-bezier(0, 1.1, 1, 1.1);
    transition-property: background-color, transform;
  }

}

.video-thumb{
  &__column{
    margin-bottom: 30px;
  }
  &__content{
    position: relative;
    max-width: 270px;
    &:hover{
      .video-thumb__overlay{
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.55));
      }
    }
  }
  &__overlay{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 23%, rgba(0, 0, 0, 0.55));
    @include transition;
  }
  &__caption{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    align-items: center;
  }
  &__name{
    display: block;
    padding: 0 15px 10px 10px;
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    color: #fff;
    display:flex;
    flex-direction:row;
    justify-content: flex-start;
    align-items: center;
    span {
      margin-right:10px;
    }
    // @include text-ellipsis(1);
  }
  &__time{
    // position: absolute;
    // right: 10px;
    // top: 0;
    margin-right:10px;
    padding-bottom:10px;
    font-size: 16px;
    font-weight: normal;
    color: #fff;
  }
  &.related-list {
    a {
      display:block;
    }
    .video-thumb__content {
      width:32.5%;
      margin: 0 5px;
      counter-increment: carousel-cell;
      max-width:none;
      @media (max-width: $breakpoint-sm){
        width:100%;
        max-width:none;
      }
    }
    img {
      width:100%;
      height:auto;
    }
    .flickity-prev-next-button {
      width:auto;
    }
  }
}

#footer-mobile-nav {
  display:none;
  width:100%;
  height:65px;
  background:#fff;
  position:fixed;
  left:0;
  bottom:0;
  z-index:5;
  padding-bottom:5px;
  border-top:#D8D8D8 1px solid;
  &.js-active {
    display:flex;
    justify-content: center;
    align-items: flex-end;
  }
  & > a {
    display:flex;
    width:24%;
    text-align:center;
    color:#717171;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    span {
      font-size:15px;
      color:#717171;
    }
    svg {
      path {
        fill:#717171;
      }
      rect {
        fill:#717171;
      }
    }
    &.active {
      span {
        color:#D0021B;
      }
      svg {
        path {
          fill:#D0021B;
        }
        rect {
          fill:#D0021B;
        }
      }
    }
  }
}
#footer-mobile-nav-open {
  display:flex;
  width:100%;
  height:calc(100vh - 155px);
  background:#fff;
  position: absolute;
  top:90px;
  left:0;
  z-index:5;
  padding:25px;
  & > div {
    display:none;
  }
  & > div[class*="-open"] {
    width:100%;
    position: relative;
    & > .row:first-child {
      margin-bottom:25px;
      .nav-name {
        justify-content: flex-start;
        align-items: center;
        display: flex;
        & > span {
          font-size:20px;
          font-weight: 700;
          margin-left:15px;
          color:#717171;
        }
        svg {
          path {
            fill:#D0021B;
          }
          rect {
            fill:#D0021B;
          }
        }
      }
      .close-nav {
        justify-content: flex-end;
        align-items: center;
        display: flex;
      }
    }
    & > .content {
      position: relative;
      height:calc(100vh - 258px);
    }
  }
}
.ui-sortable {
  .ui-state-default {
    cursor:move;
    background:none;
    border:0;
    position: relative;
    & > .row > div:first-child {
      display:flex;
      justify-content:center;
      align-items: center;
      input {
        border:0;
        background:none;
        width:20px;
        font-weight: 700;
      }
    }
    .remove {
      position: absolute;
      right:0;
      top:43%;
      z-index: 2;
    }
  }
}
.error_input {
  font-weight: 500;
  color:#D0021B;
  margin-left:5px;
}

// new styles for video desktop accordian
.video-arrow {
  font-size: 22px;
  display:block;
}

.accordian-block-desktop {
  @media (max-width: $breakpoint-md) {
    display: none;
  }
}

// add to playlist styles

input.form-control.playlist-form:focus {
    color: solid 1px var(--col-grey)!important;
    background-color: #fff;
    border-color: solid 1px var(--col-grey)!important;
    outline: var(--col-grey)!important;
    box-shadow: none!important;
}

//search video results

.search-result-block {

  & .video-thumbnail {

    @media (max-width: $breakpoint-sm) {
      margin-bottom: 10px;
      
    }

  }
  @media (max-width: $breakpoint-sm) {
    margin: 10px 0;
    border-bottom: 1px solid #d4d4d4;
  }
}

// manage playlists

// .remove-playlist {
//   @media(max-width: $brea;)
// }

.playlist-thumbnail {
  @media (max-width: $breakpoint-sm) {
    padding-right: 5px;
    padding-left: 5px;
  }
}

.playlist-order {
  @media (max-width: $breakpoint-md) {
    padding-right: 0;
  }
}

.drag-drop-title {
  @media (max-width: $breakpoint-sm) {
    margin-bottom: 10px;
  }
}




